import { gql } from '@apollo/client';

export const DELETE_PROFILE = gql`
  mutation deleteProfile($id: String!) {
    removeProfile(id: $id) {
      id
      name
    }
  }
`;
