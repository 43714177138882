import { gql } from '@apollo/client';

export const ME = gql`
  query me {
    me {
      id
      linkedInId
      firstname
      lastname
      email
      isMainTourPassed
      isProfileTourPassed
      isCvTourPassed
    }
  }
`;
