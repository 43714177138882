import React from 'react';
import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import AccountSwitcherButton from './AccountSwitcherButton';
import { IUser } from '../../types/types';
import { useAuth } from '../AuthProvider/AuthProvider';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type IProps = Pick<IUser, 'firstname' | 'lastname' | 'email' | 'id'> & {
  isOpen?: boolean;
};

const AccountSwitcher: React.FC<IProps> = React.memo(
  ({ firstname, lastname, email, id, isOpen }) => {
    const { t } = useTranslation('navbar');
    const { logout } = useAuth();
    return (
      <Menu>
        <AccountSwitcherButton
          firstname={firstname}
          lastname={lastname}
          id={id}
          isOpen={isOpen}
        />
        <MenuList
          shadow="lg"
          py="4"
          color={useColorModeValue('gray.600', 'gray.200')}
          px="3"
        >
          <Text fontWeight="medium" mb="2">
            {email}
          </Text>

          <MenuDivider />
          <Link to={'/edit-profile'}>
            <MenuItem rounded="md">{t('profile.edit')}</MenuItem>
          </Link>
          <MenuDivider />
          <MenuItem rounded="md" onClick={logout}>
            {t('profile.logout')}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }
);

export default AccountSwitcher;
