import { ECVLanguage } from 'src/types/types';
import { StateCreator } from 'zustand';
import { TAppSlice } from './types';

export const createAppSlice: StateCreator<TAppSlice> = (set) => ({
  isCreateModalOpen: false,
  createModalStep: 1,
  initialCreateState: {
    language: ECVLanguage.ENGLISH,
    title: '',
    industry: '',
    company: '',
    job: '',
    css: 'template1'
  },
  isCVOptionsMenuOpen: false,
  setCreateModalState: (value) => set({ isCreateModalOpen: value }),
  setCreateModalStep: (value) => set({ createModalStep: value }),
  setInitialCreateState: (value) =>
    set((state) => ({
      initialCreateState: { ...state.initialCreateState, ...value }
    })),
  setCVOptionsMenuState: (value) => set({ isCVOptionsMenuOpen: value })
});
