import { gql } from '@apollo/client';

export const UPDATE_COVER_LETTER = gql`
  mutation updateCoverLetter($id: ID!, $title: String, $content: String) {
    updateCoverLetter(id: $id, data: { title: $title, content: $content }) {
      id
      content
      title
    }
  }
`;

export const UPDATE_COVER_LETTER_APPLICATION = gql`
  mutation updateCoverLetter($id: ID!, $applicationId: ID) {
    updateCoverLetter(id: $id, data: { applicationId: $applicationId }) {
      id
      content
      title
      application {
        id
      }
    }
  }
`;
