import React from 'react';

import { ErrorMessage, Field, useField } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  InputProps,
  Textarea,
  TextareaProps
} from '@chakra-ui/react';

const StyledInput: React.FC<InputProps> = (props) => {
  const [, meta] = useField(props as any);
  const isInvalid: string | undefined = meta.error;

  return <Input isInvalid={!!isInvalid} {...(props as InputProps)} />;
};

const StyledTextarea: React.FC<TextareaProps> = (props) => {
  const [, meta] = useField(props as any);
  const isInvalid: string | undefined = meta.error;

  return (
    <Textarea
      fontSize={{ base: 'sm', lg: 'md' }}
      errorBorderColor="red.300"
      isInvalid={!!isInvalid}
      resize="none"
      {...(props as TextareaProps)}
    />
  );
};

export const InputField: React.FC<
  InputProps & {
    name: string;
    label: string;
    hasErrorMessage?: boolean;
    textarea?: boolean;
  }
> = ({ label, hasErrorMessage = true, textarea, ...props }) => {
  const [field] = useField(props as any);

  return (
    <FormControl colorScheme="whiteAlpha">
      <FormLabel fontSize={{ base: 'sm', lg: 'md' }}>{label}</FormLabel>
      <Field
        as={textarea ? StyledTextarea : StyledInput}
        {...field}
        isDisabled={props.isDisabled}
      />
      {hasErrorMessage && <ErrorMessage name={props.name ?? ''} />}
    </FormControl>
  );
};

export default InputField;
