import React from 'react';
import { Box, chakra, HTMLChakraProps, useToken } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Logo = React.memo(
  (props: HTMLChakraProps<'svg'> & { iconColor?: string }) => {
    const navigate = useNavigate();
    const { iconColor = 'currentColor', ...rest } = props;
    const color = useToken('colors', iconColor);
    return (
      <Box
        width="full"
        maxW="190px"
        onClick={() => navigate('/')}
        cursor="pointer"
        animation="none"
      >
        <chakra.svg viewBox="0 0 192 31" fill="none" {...rest}>
          <svg
            width="192"
            height="31"
            viewBox="0 0 192 31"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.392 11.928C19.76 9.93598 17.744 8.93998 15.344 8.93998C14.288 8.93998 13.304 9.13198 12.392 9.51598C11.504 9.89998 10.736 10.428 10.088 11.1C9.44 11.748 8.924 12.528 8.54 13.44C8.18 14.352 8 15.336 8 16.392C8 17.472 8.18 18.468 8.54 19.38C8.924 20.292 9.44 21.084 10.088 21.756C10.76 22.428 11.54 22.956 12.428 23.34C13.316 23.724 14.276 23.916 15.308 23.916C17.564 23.916 19.592 22.956 21.392 21.036V29.388L20.672 29.64C19.592 30.024 18.584 30.3 17.648 30.468C16.712 30.66 15.788 30.756 14.876 30.756C13.004 30.756 11.204 30.408 9.476 29.712C7.772 28.992 6.25999 27.996 4.93999 26.724C3.64399 25.428 2.6 23.904 1.808 22.152C1.016 20.376 0.619995 18.444 0.619995 16.356C0.619995 14.268 1.004 12.36 1.772 10.632C2.564 8.87998 3.608 7.37998 4.904 6.13198C6.224 4.85998 7.748 3.87598 9.476 3.17997C11.204 2.45998 13.016 2.09998 14.912 2.09998C15.992 2.09998 17.048 2.21998 18.08 2.45998C19.136 2.67598 20.24 3.02398 21.392 3.50397V11.928Z"
              fill={color}
            />
            <path
              d="M34.8197 2.85598L41.3717 19.38L47.9597 2.85598H55.6277L44.1797 30H38.5637L27.1517 2.85598H34.8197Z"
              fill={color}
            />
            <path
              d="M69.6683 2.85598V30H62.6123V2.85598H69.6683Z"
              fill={color}
            />
            <path
              d="M94.3828 9.80398C93.6148 9.17998 92.8468 8.72398 92.0788 8.43598C91.3108 8.12398 90.5668 7.96798 89.8468 7.96798C88.9348 7.96798 88.1908 8.18398 87.6148 8.61598C87.0388 9.04798 86.7508 9.61198 86.7508 10.308C86.7508 10.788 86.8948 11.184 87.1828 11.496C87.4708 11.808 87.8428 12.084 88.2988 12.324C88.7788 12.54 89.3068 12.732 89.8828 12.9C90.4828 13.068 91.0708 13.248 91.6468 13.44C93.9508 14.208 95.6308 15.24 96.6868 16.536C97.7668 17.808 98.3068 19.476 98.3068 21.54C98.3068 22.932 98.0668 24.192 97.5868 25.32C97.1308 26.448 96.4468 27.42 95.5348 28.236C94.6468 29.028 93.5428 29.64 92.2228 30.072C90.9268 30.528 89.4508 30.756 87.7948 30.756C84.3628 30.756 81.1828 29.736 78.2548 27.696L81.2788 22.008C82.3348 22.944 83.3788 23.64 84.4108 24.096C85.4428 24.552 86.4628 24.78 87.4708 24.78C88.6228 24.78 89.4748 24.516 90.0268 23.988C90.6028 23.46 90.8908 22.86 90.8908 22.188C90.8908 21.78 90.8188 21.432 90.6748 21.144C90.5308 20.832 90.2908 20.556 89.9548 20.316C89.6188 20.052 89.1748 19.812 88.6228 19.596C88.0948 19.38 87.4468 19.14 86.6788 18.876C85.7668 18.588 84.8668 18.276 83.9788 17.94C83.1148 17.58 82.3348 17.112 81.6388 16.536C80.9668 15.96 80.4148 15.24 79.9828 14.376C79.5748 13.488 79.3708 12.372 79.3708 11.028C79.3708 9.68398 79.5868 8.47198 80.0188 7.39198C80.4748 6.28798 81.0988 5.35198 81.8908 4.58398C82.7068 3.79198 83.6908 3.17998 84.8428 2.74798C86.0188 2.31598 87.3268 2.09998 88.7668 2.09998C90.1108 2.09998 91.5148 2.29198 92.9788 2.67597C94.4428 3.03598 95.8468 3.57598 97.1908 4.29598L94.3828 9.80398Z"
              fill={color}
            />
            <path
              d="M117.364 8.83198V30H110.308V8.83198H104.512V2.85598H123.16V8.83198H117.364Z"
              fill={color}
            />
            <path
              d="M143.933 19.92L140.765 10.884L137.597 19.92H143.933ZM145.805 25.284H135.725L134.105 30H126.581L136.913 2.85598H144.617L154.949 30H147.425L145.805 25.284Z"
              fill={color}
            />
            <path
              d="M170.579 21.4211H179.148V12.8517L170.579 12.8517V21.4211Z"
              fill={color}
            />
            <path d="M170.579 30H179.148V21.4306H170.579V30Z" fill={color} />
            <path
              d="M162 21.4211H170.569V12.8517L162 12.8517V21.4211Z"
              fill={color}
            />
            <path
              d="M183.431 8.5694L192 8.5694V3.8147e-06L183.431 3.8147e-06V8.5694Z"
              fill={color}
            />
            <path
              d="M183.431 17.1483H192V8.57882L183.431 8.57882V17.1483Z"
              fill={color}
            />
            <path
              d="M174.868 8.5694L183.438 8.5694V3.8147e-06L174.868 3.8147e-06V8.5694Z"
              fill={color}
            />
          </svg>
        </chakra.svg>
      </Box>
    );
  }
);

export default Logo;
