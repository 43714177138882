import { gql } from '@apollo/client';

export const CREATE_COVER_LETTER = gql`
  mutation createCoverLetter(
    $title: String
    $content: String
    $applicationId: ID
  ) {
    createCoverLetter(
      data: { title: $title, content: $content, applicationId: $applicationId }
    ) {
      id
      title
      content
      applicationId
    }
  }
`;
