import { useMutation } from '@apollo/client';
import { Config, DriveStep, Driver, State, driver } from 'driver.js';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_USER } from 'src/graphql/mutations/updateUser';
import { useAuthStore } from 'src/features/auth/model/authStore';

export const useMainPageTour = () => {
  const { t } = useTranslation('mainTour');
  const { setUser, user } = useAuthStore();
  const [updateUser] = useMutation(UPDATE_USER);

  const onTourComplete = useCallback(
    (tour: Driver) => {
      // @ts-ignore
      setUser({ ...user, isMainTourPassed: true });
      updateUser({
        variables: {
          isMainTourPassed: true
        }
      });
      tour.destroy();
    },
    [updateUser]
  );

  const mainPageTour: Driver = React.useMemo(
    () =>
      driver({
        showProgress: true,
        stagePadding: 4,
        disableActiveInteraction: true,
        onCloseClick: () => onTourComplete(mainPageTour),
        steps: [
          {
            element: '#desktop-menu',
            popover: {
              title: t('desktop.title'),
              description: t('desktop.description'),
              onNextClick(
                element: Element,
                step: DriveStep,
                opts: { config: Config; state: State }
              ) {
                opts.config.disableActiveInteraction = false;
                mainPageTour.moveNext();
              }
            }
          },
          {
            element: '#open-desktop-menu-button',
            popover: {
              title: t('open.title'),
              description: t('open.description'),
              disableButtons: ['next'],
              onPopoverRender(popover, opts) {
                opts.config.disableActiveInteraction = true;
                opts.state.activeElement?.addEventListener(
                  'click',
                  mainPageTour.moveNext
                );
              }
            }
          },
          {
            element: '#menu-account',
            popover: {
              title: t('account.title'),
              description: t('account.description'),
              onPopoverRender(popover, opts) {
                opts.config.disableActiveInteraction = false;
              }
            }
          },
          {
            element: '#menu-profiles',
            popover: {
              title: t('profiles.title'),
              description: t('profiles.description'),
              onNextClick() {
                onTourComplete(mainPageTour);
              }
            }
          }
        ]
      }),
    [onTourComplete, t]
  );

  return { mainPageTour };
};

export const useProfilesPageTour = () => {
  const { t } = useTranslation('profilesTour');
  const { setUser, user } = useAuthStore();
  const [updateUser] = useMutation(UPDATE_USER);

  const onTourComplete = useCallback(
    (tour: Driver) => {
      // @ts-ignore
      setUser({ ...user, isProfileTourPassed: true });
      updateUser({
        variables: {
          isProfileTourPassed: true
        }
      });
      tour.destroy();
    },
    [setUser, updateUser]
  );

  const profilesPageTour: Driver = React.useMemo(
    () =>
      driver({
        showProgress: true,
        stagePadding: 4,
        onCloseClick: () => onTourComplete(profilesPageTour),
        steps: [
          {
            element: '#create-profile-button',
            popover: {
              title: t('create.title'),
              description: t('create.description'),
              disableButtons: ['next'],
              onPopoverRender(popover, opts) {
                opts.config.disableActiveInteraction = false;
                opts.config.allowKeyboardControl = true;
                opts.state.activeElement?.addEventListener('click', () =>
                  setTimeout(profilesPageTour.moveNext, 400)
                );
              }
            }
          },
          {
            element: '.chakra-modal__content',
            popover: {
              title: t('fill.title'),
              description: t('fill.description'),
              disableButtons: ['next'],
              onPopoverRender(popover, opts) {
                // opts.config.disableActiveInteraction = true;
                opts.state.activeElement
                  ?.querySelector('#save-profile')
                  ?.addEventListener('click', () => {
                    setTimeout(profilesPageTour.moveNext, 500);
                  });
              }
            }
          },
          {
            element: '#menu-create-cv',
            popover: {
              title: t('createCV.title'),
              description: t('createCV.description'),
              disableButtons: ['next'],
              onPopoverRender(popover, opts) {
                // opts.config.disableActiveInteraction = true;
                opts.state.activeElement?.addEventListener('click', () =>
                  setTimeout(profilesPageTour.moveNext, 300)
                );
              }
            }
          },
          {
            element: '.chakra-modal__content',
            popover: {
              title: t('chooseCV.title'),
              description: t('chooseCV.description'),
              disableButtons: ['next'],
              onPopoverRender(popover, opts) {
                opts.state.activeElement
                  ?.querySelector('#choose-template')
                  ?.addEventListener('click', profilesPageTour.moveNext);
              }
            }
          },
          {
            element: '.chakra-modal__body',
            popover: {
              title: t('fillCV.title'),
              description: t('fillCV.description'),
              onNextClick: () => {
                onTourComplete(profilesPageTour);
              }
            }
          }
        ]
      }),
    [onTourComplete, t]
  );

  return { profilesPageTour };
};

export const useCVPageTour = () => {
  const { t } = useTranslation('cvTour');
  const { setUser, user } = useAuthStore();
  const [updateUser] = useMutation(UPDATE_USER);

  const onTourComplete = useCallback(
    async (tour: Driver) => {
      // @ts-ignore
      setUser({ ...user, isCvTourPassed: true });
      updateUser({
        variables: {
          isCvTourPassed: true
        }
      });
      tour.destroy();
    },
    [setUser, updateUser]
  );

  const resumePageTour: Driver = React.useMemo(
    () =>
      driver({
        showProgress: true,
        stagePadding: 4,
        disableActiveInteraction: true,
        steps: [
          {
            element: '#change-CV-name',
            popover: {
              title: t('title.title'),
              description: t('title.description'),
              disableButtons: ['previous']
            }
          },
          {
            element: '#CV-editor',
            popover: {
              title: t('editor.title'),
              description: t('editor.description')
            }
          },
          {
            element: '#CV-options',
            popover: {
              title: t('options.title'),
              description: t('options.description'),
              onNextClick: (
                element: Element,
                step: DriveStep,
                options: { config: Config; state: State }
              ) => {
                options.config.disableActiveInteraction = false;
                resumePageTour.moveNext();
              }
            }
          },
          {
            element: '#actions-button',
            popover: {
              title: t('actions.title'),
              description: t('actions.description'),
              disableButtons: ['next'],
              onPopoverRender(popover, opts) {
                opts.config.disableActiveInteraction = true;
                opts.state.activeElement?.addEventListener('click', () =>
                  setTimeout(resumePageTour.moveNext, 400)
                );
              }
            }
          },
          {
            element: '#actions-button > div > div.chakra-menu__menu-list',
            popover: {
              title: t('actionsMenu.title'),
              description: t('actionsMenu.description')
            }
          },
          {
            element: '#CV-versions',
            popover: {
              title: t('versions.title'),
              description: t('versions.description'),
              onNextClick: () => {
                onTourComplete(resumePageTour);
              },
              onPopoverRender: async (popover, opts) => {
                opts.config.disableActiveInteraction = true;
                opts.state.activeElement?.addEventListener('click', () =>
                  setTimeout(resumePageTour.moveNext, 200)
                );
              }
            }
          }
        ],
        onCloseClick: () => onTourComplete(resumePageTour)
      }),
    [onTourComplete, t]
  );

  return { resumePageTour };
};
