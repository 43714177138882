import { gql } from '@apollo/client';

export const UPDATE_RECOMMENDATION_BY_RECOMMENDER = gql`
  mutation updateRecommendationByRecommender(
    $id: ID!
    $content: String!
    $isApproved: Boolean
  ) {
    updateRecommendationByRecommender(
      data: { id: $id, content: $content, isApproved: $isApproved }
    ) {
      id
      content
      isApproved
    }
  }
`;
