import { Flex, Box, Image, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface TemplateCardProps {
  img: string;
  name: string;
  id: string;
}

const TemplateCard: React.FC<TemplateCardProps> = ({ img, name, id }) => {
  const { t } = useTranslation('createCV');
  return (
    <Flex p={10} alignItems="center" justifyContent="center">
      <Box
        id={id}
        bg={useColorModeValue('white', 'gray.800')}
        maxW="sm"
        borderWidth="1px"
        rounded="lg"
        shadow="lg"
        position="relative"
      >
        <Image
          src={img}
          alt={`Picture of ${name}`}
          height={'240px'}
          roundedTop="lg"
        />

        <Box p="6">
          <Flex mt="1" justifyContent="space-between" alignContent="center">
            <Box
              // fontSize="lg"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {t('template.caption')}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default TemplateCard;
