import { StateCreator } from 'zustand';
import { TProfilesSlice } from './types';
import {
  fetchProfiles,
  fetchDeleteProfile,
  fetchCreateProfile,
  fetchUpdateProfile
} from './api';

export const createProfilesSlice: StateCreator<TProfilesSlice> = (set) => ({
  profiles: [],
  initProfiles: async () => {
    const profiles = await fetchProfiles();
    if (profiles) {
      set({ profiles });
    }
  },
  setProfiles: (value) => set({ profiles: value }),
  createProfile: async (name, content) => {
    const newProfile = await fetchCreateProfile(name, content);
    if (newProfile) {
      set((state) => ({
        profiles: [...state.profiles, newProfile]
      }));
      // getToast({
      //   title: 'Profile created.',
      //   description: `Profile ${newProfile.name} successfully created.`,
      // });
    }
  },
  updateProfile: async (id, name, content) => {
    const updatedProfile = await fetchUpdateProfile(id, name, content);
    if (updatedProfile) {
      set((state) => ({
        profiles: state.profiles.map((pf) =>
          pf.id === updatedProfile.id ? updatedProfile : pf
        )
      }));
      // getToast({
      //   title: 'Profile updated.',
      //   description: `Profile ${updatedProfile.name} successfully updated.`,
      // });
    }
  },
  deleteProfile: async (id) => {
    const deletedProfile = await fetchDeleteProfile(id);
    if (deletedProfile?.id === id) {
      set((state) => ({
        profiles: state.profiles.filter((pf) => pf.id !== deletedProfile.id)
      }));
      // getToast({
      //   title: 'Delete success.',
      //   description: `Profile ${deletedProfile.name} successfully deleted.`,
      // });
    }
  }
});
