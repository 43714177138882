import { gql } from '@apollo/client';

export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($code: String!) {
    confirmEmail(data: { code: $code }) {
      accessToken
      refreshToken
      user {
        id
        linkedInId
        firstname
        lastname
        email
      }
    }
  }
`;
