import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FIND_ALL_APPLICATIONS, FIND_COVER_LETTER } from 'src/graphql/queries';
import { IApplication } from 'src/types/types';
import { MdOutlineSave } from 'react-icons/md';
import { DeleteIcon, DownloadIcon, SpinnerIcon } from '@chakra-ui/icons';
import Alert from 'src/components/Alert/Alert';
import { MenuItem } from '@chakra-ui/react';
import {
  DELETE_COVER_LETTER,
  IMPROVE_COVER_LETTER,
  TRANSFORM_COVER_LETTER_TO_PDF,
  UPDATE_COVER_LETTER,
  UPDATE_COVER_LETTER_APPLICATION
} from 'src/graphql/mutations';
import { useAppToast } from './useAppToast';
import { useDebouncedCallback } from 'use-debounce';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';

export const useCoverLetter = () => {
  const { t } = useTranslation('coverLetters');
  const { letterId } = useParams();
  const nav = useNavigate();
  const [isOpen, setIsOpen] = useState({ application: false });
  const [content, setContent] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const { showToast, closeToast } = useAppToast('coverLetter');
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };
  const [application, setApplication] = useState<IApplication | null>(null);
  const [applications, setApplications] = useState<IApplication[]>([]);
  const [applicationTitle, setApplicationTitle] = useState('');
  const [autocompleteOptions, setOptions] = useState<
    { id: string; name: string; value: string }[]
  >([]);
  const [getRecommendation, { loading: recommendationLoading }] = useLazyQuery(
    FIND_COVER_LETTER,
    {
      variables: {
        id: letterId
      },
      onCompleted: (data) => {
        if (data.findOneCoverLetter) {
          setContent(data.findOneCoverLetter.content);
          setApplication(data.findOneCoverLetter.application);
          setApplicationTitle(data.findOneCoverLetter.application.title);
        }
      },
      onError
    }
  );
  const [updateCoverLetter] = useMutation(UPDATE_COVER_LETTER, {
    variables: {
      id: letterId
    },
    onCompleted: (data) => {
      if (data.updateCoverLetter) {
        setContent(data.updateCoverLetter.content);
      }
    },
    onError
  });
  const [transformToPdf] = useMutation(TRANSFORM_COVER_LETTER_TO_PDF, {
    onCompleted: (data) => {
      saveAs(
        'data:application/pdf;base64,' + data.transformCoverLetterToPdf,
        `${
          application?.company
            ? `${application?.company}_cover_letter`
            : 'cover_letter'
        }.pdf`
      );
      closeToast();
    },
    onError
  });
  const [setCoverLetterApplication] = useMutation(
    UPDATE_COVER_LETTER_APPLICATION,
    {
      variables: {
        id: letterId
      },
      onError
    }
  );
  const [improveCoverLetter] = useMutation(IMPROVE_COVER_LETTER, {
    variables: {
      id: letterId
    },
    onCompleted: (data) => {
      if (data.improveCoverLetter) {
        setContent(data.improveCoverLetter.content);
        showToast({
          content: t('successUpdate'),
          status: 'success',
          update: true
        });
      }
    },
    onError
  });
  const [getApplications, { loading: applicationsLoading }] = useLazyQuery(
    FIND_ALL_APPLICATIONS,
    {
      onCompleted: (data: {
        findAllApplications: {
          edges: Array<{ node: IApplication }>;
        };
      }) => {
        setApplications(
          data.findAllApplications.edges.map((edge) => edge.node)
        );
        setOptions(
          data.findAllApplications.edges.map(({ node }) => ({
            id: node.id,
            name: node.title,
            value: node.title
          }))
        );
        setPageLoading(false);
      },
      onError: (error) => {
        onError(error);
        setPageLoading(false);
      }
    }
  );
  const [deleteCoverLetterMutation] = useMutation(DELETE_COVER_LETTER, {
    variables: {
      id: letterId
    },
    onCompleted: async () => {
      await closeToast();
      nav('/cover-letters');
    },
    onError
  });

  const debouncedGetApplications = useDebouncedCallback(
    async (query: string) => {
      await getApplications({
        variables: {
          query,
          first: 10
        }
      });
    },
    1500
  );

  const onChangeHandler = (value: string) => setContent(value);

  const handleUpdate = async () => {
    await updateCoverLetter({
      variables: {
        content
      }
    });
  };
  const handleImprove = async () => {
    showToast({
      content: t('waitingForPreview'),
      status: 'loading',
      duration: null
    });
    await improveCoverLetter({
      variables: {
        content
      }
    });
  };
  const handleSavePdf = async () => {
    showToast({
      content: t('waitingForDownload'),
      status: 'loading'
    });
    await transformToPdf({ variables: { id: letterId } });
  };
  const handleApplicationTitleChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const { value } = e.target;
    // setApplicationTitle(value);
    // console.log('VALUE', value);
    await debouncedGetApplications(value);
  };
  const handleSelectApplication = (value: string) => {
    const selectedApplication = applications.find(
      (application) => application?.id === value
    );
    if (selectedApplication) {
      showToast({
        content: t('coverLetterInProcess'),
        status: 'loading',
        duration: null
      });
      setCoverLetterApplication({
        variables: {
          applicationId: selectedApplication.id
        }
      }).then(({ data }) => {
        if (
          data?.updateCoverLetter?.application?.id === selectedApplication.id
        ) {
          setApplication(selectedApplication);
          showToast({
            content: t('coverLetterApplicationIs', {
              title: selectedApplication.title
            }),
            status: 'success',
            update: true
          });
        }
      });
    }
  };
  const handleRemoveCoverLetter = async () => {
    showToast({
      content: t('removeInProgress'),
      status: 'loading',
      duration: null
    });
    await deleteCoverLetterMutation();
  };
  const menuItems = [
    {
      id: 0,
      onClickHandler: handleUpdate,
      text: t('actions.save'),
      icon: React.createElement(MdOutlineSave)
    },
    {
      id: 1,
      onClickHandler: handleImprove,
      text: t('actions.aiRewrite'),
      icon: React.createElement(SpinnerIcon)
    },
    {
      id: 2,
      onClickHandler: handleSavePdf,
      text: t('actions.download'),
      icon: React.createElement(DownloadIcon)
    },
    {
      id: 3,
      element: (
        <Alert
          title={t('deleteCoverLetter')}
          submitText={t('actions.delete')}
          body={<div>{t('deleteCoverLetterConfirmation')}</div>}
          cancelText={t('actions.cancel')}
          submitColorScheme="red"
          submitCallback={handleRemoveCoverLetter}
        >
          <MenuItem icon={<DeleteIcon />}>{t('actions.delete')}</MenuItem>
        </Alert>
      )
    }
  ];

  useEffect(() => {
    getRecommendation();
    getApplications({
      variables: {
        query: ''
      }
    });
  }, [getRecommendation, getApplications]);

  return {
    content,
    application,
    isOpen,
    setIsOpen,
    menuItems,
    onChangeHandler,
    applicationTitle,
    handleApplicationTitleChange,
    autocompleteOptions,
    recommendationLoading,
    applicationsLoading,
    handleSelectApplication,
    pageLoading
  };
};
