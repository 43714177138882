import * as yup from 'yup';
import i18n from 'i18next';

export const validationEditProfileSchema = yup.object().shape({
  firstname: yup
    .string()
    .max(16)
    .required(i18n.t('editProfile:form.validation.name')),
  lastname: yup
    .string()
    .max(16)
    .required(i18n.t('editProfile:form.validation.surname')),
  email: yup
    .string()
    .email()
    .max(36)
    .required(i18n.t('editProfile:form.validation.email'))
});
