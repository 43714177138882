import { defineStyleConfig } from '@chakra-ui/react';

export const inputTheme = defineStyleConfig({
  baseStyle: {
    errorBorderColor: 'red.300',
    fontSize: {
      base: 'sm',
      lg: 'md'
    }
  }
});
