import React from 'react';

import 'driver.js/dist/driver.css';
import {
  Button,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import AppModal from 'src/components/AppModal/AppModal';
import { TProfile } from 'src/types/types';
import { useTranslation } from 'react-i18next';

type Props = {
  profile: TProfile;
  handleChange: React.ChangeEventHandler;
  isOpen: boolean;
  onClose: () => void;
  handleDeleteProfile: () => void;
  handleSubmit: () => void;
};

const CreateProfileModal: React.FC<Props> = ({
  profile,
  handleChange,
  isOpen,
  onClose,
  handleDeleteProfile,
  handleSubmit
}) => {
  const { t } = useTranslation('profiles');
  const { t: commonT } = useTranslation('common');

  return (
    <AppModal
      title={
        <FormControl style={{ display: 'flex' }}>
          <FormLabel
            style={{
              marginBottom: '.25em',
              marginRight: '.5em'
            }}
            cursor="text"
            opacity={'.4'}
            _hover={{ opacity: '1' }}
          >
            <EditIcon />
          </FormLabel>
          <Input
            fontWeight={'bold'}
            textTransform={'capitalize'}
            fontSize={'18px'}
            variant="unstyled"
            placeholder={`${t('modal.enter')}...`}
            name="name"
            defaultValue={profile.name}
            onChange={handleChange}
            autoFocus
          />
        </FormControl>
      }
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          {profile.id && (
            <Menu>
              <MenuButton
                as={Button}
                variant="outline"
                colorScheme="red"
                mr={3}
              >
                {/* <Button
                  variant="outline"
                  colorScheme="red"
                  mr={3}
                  //   isLoading={deleteProfileLoading}
                > */}
                {commonT('delete')}
                {/* </Button> */}
              </MenuButton>
              <MenuList minW="fit-content">
                <MenuItem as="div" w="fit-content">
                  <Button variant="outline" onClick={onClose}>
                    {commonT('cancel')}
                  </Button>
                </MenuItem>
                <MenuItem as="div" w="fit-content">
                  <Button colorScheme="red" onClick={handleDeleteProfile}>
                    {commonT('confirm')}
                  </Button>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          <Button
            id="save-profile"
            type="submit"
            colorScheme="green"
            // isLoading={createProfileLoading || updateProfileLoading}
            onClick={handleSubmit}
          >
            {commonT('save')}
          </Button>
        </>
      }
    >
      <FormControl id="decription">
        <FormLabel>{t('modal.description.label')}</FormLabel>
        <Textarea
          rows={10}
          defaultValue={profile.content}
          onChange={handleChange}
          placeholder={t('modal.description.placeholder')}
          name="content"
        />
      </FormControl>
    </AppModal>
  );
};

export default CreateProfileModal;
