import React from 'react';
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef
} from '@tanstack/react-table';

import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';

import ReactTable from 'src/components/Table/ReactTable';
import useAppStore from 'src/store';
import { ECVLanguage, IResume } from 'src/types/types';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  Stack,
  Badge,
  Image
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Alert from 'src/components/Alert/Alert';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import enFlag from '../../assets/images/gb.svg';
import esFlag from '../../assets/images/es.svg';
import frFlag from '../../assets/images/fr.svg';
import { MdOutlineDifference } from 'react-icons/md';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const CVsTable: React.FC<{
  setAsDefault: (id: string) => void;
  handleDelete: (id: string) => void;
  onRowClick: (id: string) => void;
}> = ({ setAsDefault, handleDelete, onRowClick }) => {
  const { t } = useTranslation('myCVs');
  const { t: tApplication } = useTranslation('applications');
  const columnVisibility:
    | {
        profile: boolean;
        industry: boolean;
        company: boolean;
        job: boolean;
        language: boolean;
      }
    | undefined = useBreakpointValue({
    base: {
      profile: false,
      industry: false,
      company: true,
      job: true,
      language: false
    },
    sm: {
      profile: false,
      industry: false,
      company: true,
      job: true,
      language: false
    },
    md: {
      profile: true,
      industry: false,
      company: true,
      job: true,
      language: true
    },
    lg: {
      profile: true,
      industry: true,
      company: true,
      job: true,
      language: true
    }
  });
  const resumes = useAppStore((state) => state.resumes);
  const languageFlag: Record<ECVLanguage, string> = React.useMemo(
    () => ({
      [ECVLanguage.ENGLISH]: enFlag,
      [ECVLanguage.SPANISH]: esFlag,
      [ECVLanguage.FRENCH]: frFlag
    }),
    []
  );

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState('');

  const columns = React.useMemo<ColumnDef<IResume, any>[]>(
    () => [
      {
        accessorKey: 'title',
        header: () => t('table.title'),
        accessorFn: (row) =>
          row.isDefault ? `${row.title} (default)` : row.title,
        cell: (props) => {
          return (
            <Stack>
              <Text>{props.getValue()}</Text>
              <Badge
                textTransform="uppercase"
                fontSize="xs"
                colorScheme={props.row.original.published ? 'green' : 'gray'}
                borderRadius={20}
                textAlign="center"
                maxW={20}
              >
                {props.row.original.published
                  ? t('filterByCv.published')
                  : t('filterByCv.draft')}
              </Badge>
            </Stack>
          );
        }
      },
      {
        accessorKey: 'profile',
        header: () => t('table.profile'),
        accessorFn: (row) => row.profile?.name || ''
      },
      {
        accessorKey: 'industry',
        header: () => t('table.industry')
      },
      {
        accessorKey: 'company',
        header: () => t('table.company')
      },
      {
        accessorKey: 'job',
        header: () => t('table.job')
      },
      {
        accessor: 'language',
        header: t('table.language'),
        cell: (props) => {
          const language: ECVLanguage = props.row.original.language;
          return (
            <Image
              src={languageFlag[language]}
              alt={language}
              w={7}
              mx="auto"
            />
          );
        }
      },
      {
        accessorKey: 'id',
        header: () => null,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => {
          const id = props.getValue();

          return (
            <Menu direction={'ltr'}>
              <MenuButton
                as={IconButton}
                icon={<HiOutlineDotsVertical />}
                variant="ghost"
                _hover={{
                  bg: 'gray.300'
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList onClick={(e) => e.stopPropagation()} p={0}>
                <MenuItem p="0" as={Link} to={`/my-cvs/${id}`}>
                  <Button
                    leftIcon={<EditIcon />}
                    variant="ghost"
                    colorScheme="blue"
                    w="100%"
                  >
                    {t('actions.edit')}
                  </Button>
                </MenuItem>
                <Alert
                  title={t('setCVasDefault')}
                  body={t('setAsDefaultConfirmation', {
                    title: props.row.original.title
                  })}
                  submitText={t('actions.accept')}
                  cancelText={t('actions.cancel')}
                  submitColorScheme="blue"
                  submitCallback={() => setAsDefault(id)}
                >
                  <Button
                    leftIcon={<MdOutlineDifference />}
                    variant="ghost"
                    colorScheme="gray"
                    w="100%"
                  >
                    {t('setAsDefault')}
                  </Button>
                </Alert>
                <MenuDivider m={0} />
                <MenuItem
                  as={() => (
                    <Alert
                      title={tApplication('deleteApplication')}
                      body={tApplication('deleteApplicationConfirmation')}
                      submitText={t('actions.confirm')}
                      cancelText={t('actions.cancel')}
                      submitColorScheme={'red'}
                      submitCallback={() => handleDelete(id)}
                    >
                      <Button
                        leftIcon={<DeleteIcon />}
                        variant="ghost"
                        colorScheme="red"
                        w="100%"
                      >
                        {t('actions.delete')}
                      </Button>
                    </Alert>
                  )}
                  p="0"
                />
              </MenuList>
            </Menu>
          );
        }
      }
    ],
    [handleDelete, languageFlag, setAsDefault, t]
  );

  const table = useReactTable<IResume>({
    data: resumes,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility
      // pagination: {
      //   pageIndex: 0,
      //   pageSize: 6,
      // },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  });

  return (
    <ReactTable<IResume>
      table={table}
      searchValue={globalFilter}
      onSearchHandler={(value) => setGlobalFilter(String(value))}
      onRowClick={onRowClick}
    />
  );
};

export default React.memo(CVsTable);
