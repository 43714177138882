import { gql } from '@apollo/client';

export const ATS_TEST = gql`
  mutation atsTest($id: ID!) {
    atsTest(id: $id) {
      isPassed
      content
    }
  }
`;
