import { FC } from 'react';
import { Spinner, Box } from '@chakra-ui/react';
import React from 'react';
import { useLoading } from 'src/shared/ui/loader/model';

export const Loader: FC = () => {
  const { isLoading } = useLoading();
  if (!isLoading) return null;
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      zIndex="9999"
    >
      <Spinner size="xl" />
    </Box>
  );
};
