import { gql } from '@apollo/client';

export const FIND_COVER_LETTER = gql`
  query findOneCoverLetter($id: String!) {
    findOneCoverLetter(id: $id) {
      application {
        title
        company
        description
      }
      content
      title
    }
  }
`;
