import { gql } from '@apollo/client';

export const SUBSCRIPTION = gql`
  query subscription {
    subscription {
      id
      plan
    }
  }
`;
