import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation resetPassword($newPassword: String!, $code: String!) {
    resetPassword(data: { newPassword: $newPassword, code: $code }) {
      accessToken
      refreshToken
      user {
        id
        linkedInId
        firstname
        lastname
        email
      }
    }
  }
`;
export const RESET_PASSWORD_AUTHORIZED = gql`
  mutation changePassword($newPassword: String!) {
    changePassword(data: { newPassword: $newPassword }) {
      id
      linkedInId
      firstname
      lastname
      email
      role
    }
  }
`;
export const SEND_RESET_PASSWORD_LINK = gql`
  mutation sendResetPasswordLink($email: String!) {
    sendResetPasswordLink(data: { email: $email })
  }
`;
