import React, { useEffect } from 'react';

import { Box, Icon, IconButton, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IoIosArrowDroprightCircle } from 'react-icons/io';

import Navbar from './Navbar';

const NavbarWrapper = () => {
  const { getButtonProps, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    document.addEventListener('click', onClose);
    return () => document.removeEventListener('click', onClose);
  }, [onClose]);

  return (
    <motion.div
      animate={{ width: isOpen ? 300 : 64 }}
      style={{
        whiteSpace: 'nowrap',
        position: 'absolute',
        left: '0',
        height: '100vh',
        top: '0',
        zIndex: 100
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box>
        <IconButton
          {...getButtonProps()}
          id="open-desktop-menu-button"
          pos="absolute"
          zIndex={200}
          right={isOpen ? -2 : -5}
          top={4}
          h={8}
          width={6}
          minW={6}
          borderStartRadius={0}
          bg="gray.900"
          p={0}
          colorScheme="gray"
          color="gray.400"
          _hover={{
            bg: 'gray.900'
          }}
          _active={{
            bg: 'gray.900'
          }}
          icon={
            <Icon
              w={4}
              h={4}
              as={IoIosArrowDroprightCircle}
              transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
              transition="rotate 500ms easy-in-out"
              // rotate={isOpen ? '180deg' : '0'}
            />
          }
        />
      </Box>
      <Navbar isOpen={isOpen} onClose={onClose} />
    </motion.div>
  );
};

export default NavbarWrapper;
