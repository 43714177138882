import React, { useState, useEffect } from 'react';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import MobileTopBar from '../Navbar/MobileTopBar';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { HiChevronRight } from 'react-icons/hi';
import type { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import { ApolloError, useMutation } from '@apollo/client';
import { EditIcon } from '@chakra-ui/icons';
import { useDebouncedCallback } from 'use-debounce';
import { UPDATE_RESUME } from 'src/graphql/mutations';
import CreateCVModal from '../CreateCVModal/CreateCVModal';
import NavbarWrapper from '../Navbar/NavbarWrapper';
import useAppStore from 'src/store';
import { IResume } from 'src/types/types';
import { useAppToast } from 'src/hooks/useAppToast';
import { Loader } from 'src/shared/ui';

const UserBreadcrumb: BreadcrumbComponentType<'id' | 'letterId'> = ({
  match
}) => {
  const [name, setName] = useState('');
  const [letterName] = useState();
  const resumes = useAppStore((store) => store.resumes);
  const [updateResume] = useMutation(UPDATE_RESUME);
  const { showToast, closeToast } = useAppToast('cv-page');
  const debouncedUpdateCVData = useDebouncedCallback(async (title: string) => {
    showToast({
      content: 'Updating CV name...',
      status: 'loading'
    });
    await updateResume({ variables: { id: match.params.id, title } });
    closeToast();
  }, 1500);
  const handleUpdateCVData: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value } = e.target as HTMLInputElement | HTMLTextAreaElement;
    debouncedUpdateCVData(value.trim());
  };

  useEffect(() => {
    const resume = resumes.find(
      (resume) => resume.id === match.params.id
    ) as IResume;
    if (resume) {
      setName(resume?.title ?? match.params.id ?? '');
    }
  }, [match.params.id, resumes, setName]);

  return (
    <div id="change-CV-name">
      {name && !match.params.letterId ? (
        <FormControl display="flex" width="fit-content" alignItems="center">
          <FormLabel
            style={{
              marginBottom: '.25em',
              marginRight: '.5em'
            }}
            cursor="text"
            opacity={'.4'}
            _hover={{ opacity: '1' }}
          >
            <EditIcon w={3} h={3} />
          </FormLabel>
          <Input
            fontWeight="semibold"
            fontSize="sm"
            variant="unstyled"
            placeholder="Enter title here..."
            defaultValue={name}
            onChange={handleUpdateCVData}
            name="title"
            autoFocus
          />
        </FormControl>
      ) : match.params.letterId ? (
        letterName
      ) : (
        match.params.id
      )}
    </div>
  );
};

const Layout: React.FC = React.memo(() => {
  const breadcrumbs = useBreadcrumbs([
    {
      path: '/my-cvs/:id',
      breadcrumb: UserBreadcrumb
    }
  ]);
  const { showToast, closeToast } = useAppToast('layout');
  const onError = React.useCallback(
    async (error: ApolloError) => {
      await closeToast();
      showToast({ content: error.message, status: 'error' });
    },
    [closeToast, showToast]
  );
  const initProfiles = useAppStore((store) => store.initProfiles);
  const initApplications = useAppStore((store) => store.initApplications);
  const initCoverLetters = useAppStore((store) => store.initCoverLetters);
  const initRecommendations = useAppStore((store) => store.initRecommendations);
  const initResumes = useAppStore((store) => store.initResumes);
  const { pathname } = useLocation();

  const initialDataRequest = React.useCallback(async () => {
    try {
      await Promise.all([
        initProfiles(),
        initApplications(),
        initCoverLetters(),
        initRecommendations(),
        initResumes()
      ]);
    } catch (error) {
      console.log('ERROR', error);
      onError(error);
    }
  }, [
    initApplications,
    initCoverLetters,
    initProfiles,
    initRecommendations,
    initResumes,
    onError
  ]);

  useEffect(() => {
    initialDataRequest();
  }, [initialDataRequest]);

  return (
    <Flex h="100vh" flexDirection="column" w={'full'} pos="relative">
      <MobileTopBar isCVPage={pathname.includes('/cv/')} />
      <Box
        display="flex"
        gap={0}
        w="full"
        h={{ base: 'calc(100vh - 57px)', lg: '100vh' }}
      >
        <Box display={{ base: 'none', lg: 'flex' }}>
          <NavbarWrapper />
        </Box>
        <Flex
          // p={{ base: 0, lg: 6 }}
          pl={{ base: 0, lg: 20 }}
          flexGrow={1}
          maxW="8xl"
          mx="auto"
        >
          <Box
            w={{ base: '100vw', lg: 'calc(100vw - 104px)' }}
            h="full"
            p="1em"
            overflowY="auto"
            overflowX="hidden"
            pos="relative"
            css={{
              '&::-webkit-scrollbar': {
                width: '14px'
              },
              '&::-webkit-scrollbar-track': {
                width: '14px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(26,30,40,0.5)',
                border: '4px solid white',
                borderRadius: '16px'
              }
            }}
          >
            {!pathname.startsWith('/confirm-data/') && (
              <Box pb="4">
                <Breadcrumb
                  // maxW="7xl"
                  // w="full"
                  separator={
                    <Box as={HiChevronRight} color="gray.500" fontSize="lg" />
                  }
                >
                  {breadcrumbs.map(({ breadcrumb, match }) => {
                    return (
                      <BreadcrumbItem key={match.pathname}>
                        <Link to={match.pathname}>{breadcrumb}</Link>
                      </BreadcrumbItem>
                    );
                  })}
                </Breadcrumb>
              </Box>
            )}
            <Outlet />
          </Box>
        </Flex>
      </Box>
      <CreateCVModal />
      <Loader />
    </Flex>
  );
});

export default Layout;
