import { gql } from '@apollo/client';

export const GET_RECOMMENDER_RECOMMENDATION = gql`
  query getRecommendationByRecommender($id: ID!, $email: String!) {
    getRecommendationByRecommender(data: { id: $id, email: $email }) {
      content
      title
      isApproved
    }
  }
`;
