import { create } from 'zustand';

interface IState {
  isLoading: boolean;
}

interface IActions {
  setLoading: (isLoading: boolean) => void;
}

export const useLoading = create<IState & IActions>()((set) => ({
  isLoading: false,
  setLoading: (isLoading: boolean) => set({ isLoading })
}));
