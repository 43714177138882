import { useNavigate, useParams } from 'react-router-dom';
import { useAppToast } from './useAppToast';
import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_RECOMMENDATION } from 'src/graphql/mutations';
import { RecommendationInput } from './useCVActions';
import { useDisclosure } from '@chakra-ui/react';
import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const initialRecommendationInput = {
  company: '',
  name: '',
  email: '',
  currentCompany: '',
  currentJobTitle: '',
  recommenderJobTitle: '',
  recommendeeJobTitle: ''
};

export const useCreateRecommendation = () => {
  const { id } = useParams<{ id: string }>();
  const { t: tRecommendation } = useTranslation('recommendations');
  const navigate = useNavigate();
  const { showToast, closeToast } = useAppToast('recommmendation');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };

  const initialRecommendationFormValues: {
    label: string;
    name: keyof RecommendationInput;
    value: string;
  }[] = [
    {
      label: tRecommendation('recommenderName'),
      name: 'name',
      value: ''
    },
    {
      label: tRecommendation('recommenderEmail'),
      name: 'email',
      value: ''
    },
    {
      label: tRecommendation('currentCompanyRecommender'),
      name: 'currentCompany',
      value: ''
    },
    {
      label: tRecommendation('currentJobTitleRecommender'),
      name: 'currentJobTitle',
      value: ''
    },
    {
      label: tRecommendation('contextCompany'),
      name: 'company',
      value: ''
    },
    {
      label: tRecommendation('context.recommenderJobTitle'),
      name: 'recommenderJobTitle',
      value: ''
    },
    {
      label: tRecommendation('context.recommendeeJobTitle'),
      name: 'recommendeeJobTitle',
      value: ''
    }
  ];

  const [
    createRecommendationMutation,
    { loading: createRecommendationLoading }
  ] = useMutation(CREATE_RECOMMENDATION, {
    onCompleted: (data) => {
      if (data.createRecommendation) {
        navigate(`/recommendations/${data.createRecommendation.id}`);
      }
    },
    onError
  });
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    touched,
    errors,
    isValid
  } = useFormik<RecommendationInput>({
    initialValues: initialRecommendationInput,
    onSubmit: async (values) => {
      await createRecommendationMutation({
        variables: { resumeId: id, ...values }
      });
    },
    validate: (values) => {
      const errors: FormikErrors<RecommendationInput> = {};
      initialRecommendationFormValues.forEach(({ name }) => {
        if (!values[name]) {
          errors[name] = tRecommendation('required');
        }
        if (
          name === 'email' &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = tRecommendation('invalidEmail');
        }
      });
      return errors;
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true
  });

  return {
    initialRecommendationFormValues,
    isOpen,
    onOpen,
    onClose,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    touched,
    errors,
    isValid,
    createRecommendationLoading
  };
};
