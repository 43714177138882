import React, { FC } from 'react';
import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useColorModeValue as mode,
  useBreakpointValue
} from '@chakra-ui/react';

import { HiOutlineMenu } from 'react-icons/hi';
import { MdFormatAlignRight } from 'react-icons/md';
import { useMobileMenuState } from 'src/hooks/useMobileMenuState';
import Logo from '../Logo/Logo';
import Navbar from './Navbar';
import { useSearchParams } from 'react-router-dom';

const MobileTopBar: FC<{
  display?: { [breakpoint: string]: string };
  isCVPage?: boolean;
}> = React.memo(({ display, isCVPage }) => {
  const { isOpen, onClose, onOpen } = useMobileMenuState();
  const {
    isOpen: isSidebarOpen,
    onClose: onSidebarClose,
    onOpen: onSidebarOpen
  } = useMobileMenuState();
  const [searchParams, setSearchParams] = useSearchParams();
  const showLogo = useBreakpointValue({
    base: false,
    lg: true
  });

  return (
    <Flex
      align="center"
      justify="space-between"
      py="2"
      px="4"
      bg={'gray.900'}
      display={display ?? { base: 'flex', lg: 'none' }}
      borderBottomWidth="1px"
      color={'white'}
    >
      {isCVPage && showLogo && <Logo h="6" iconColor="white" />}
      {!isCVPage && <Logo h="6" iconColor="white" />}
      <IconButton
        onClick={onOpen}
        variant="unstyled"
        display="flex"
        cursor="pointer"
        aria-label="Menu"
        icon={<HiOutlineMenu fontSize="1.5rem" />}
      />
      {isCVPage ? (
        <IconButton
          onClick={() => {
            onSidebarOpen();
            setTimeout(() => setSearchParams({ sidebar: 'open' }), 0);
          }}
          variant="unstyled"
          display={{ base: 'flex', lg: 'none' }}
          cursor="pointer"
          aria-label="SidebarMenu"
          icon={<MdFormatAlignRight fontSize="1.5rem" />}
        />
      ) : null}
      <Drawer
        placement="left"
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={mode('white', 'gray.800')}
          shadow="none"
          position="relative"
          maxW="64"
        >
          <Navbar
            width="full"
            height="full"
            border="0"
            isOpen
            onClose={onClose}
          />
          <DrawerCloseButton
            position="absolute"
            color="white"
            right="-8"
            top="0"
          />
        </DrawerContent>
      </Drawer>
      <Drawer
        size="xs"
        placement="right"
        isOpen={isSidebarOpen}
        blockScrollOnMount={false}
        onClose={() => {
          onSidebarClose();
          searchParams.delete('sidebar');
          setSearchParams(searchParams);
        }}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={mode('white', 'gray.800')}
          shadow="none"
          position="relative"
        >
          <Box id="sidebar-content" p={4} overflowY="auto">
            {/* <Heading as="h3" fontWeight={600} fontSize="lg" mb={4}>
              CV Options
            </Heading> */}
          </Box>
          <DrawerCloseButton
            position="absolute"
            color="white"
            left="-8"
            top="0"
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
});

export default MobileTopBar;
