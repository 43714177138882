import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  TAppSlice,
  TCoverLettersSlice,
  TResumesSlice,
  createAppSlice,
  createCoverLettersSlice,
  createResumesSlice,
  createApplicationsSlice,
  TApplicationsSlice,
  createRecommendationsSlice,
  TRecommendationsSlice,
  createProfilesSlice,
  TProfilesSlice
} from './slices';

type TAppStore = TAppSlice &
  TCoverLettersSlice &
  TResumesSlice &
  TApplicationsSlice &
  TRecommendationsSlice &
  TProfilesSlice;

const useAppStore = create<TAppStore>()(
  devtools(
    persist(
      (...a) => ({
        ...createAppSlice(...a),
        ...createCoverLettersSlice(...a),
        ...createResumesSlice(...a),
        ...createApplicationsSlice(...a),
        ...createRecommendationsSlice(...a),
        ...createProfilesSlice(...a)
      }),
      { name: 'appStore' }
    )
  )
);

export default useAppStore;
