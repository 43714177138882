// import { ECVLanguage } from 'src/types/types';
import { StateCreator } from 'zustand';
import { TCoverLettersSlice } from './types';
import { fetchCoverLetters } from './api';

export const createCoverLettersSlice: StateCreator<TCoverLettersSlice> = (
  set,
  get
) => ({
  initCoverLetters: async () => {
    const coverLetters = await fetchCoverLetters();
    if (coverLetters) {
      get().setCoverLetters(coverLetters);
    }
  },
  coverLetters: [],
  setCoverLetters: (value) => set({ coverLetters: value })
});
