import React, { useState } from 'react';

import {
  Stack,
  Button,
  FormLabel,
  FormControl,
  Textarea
} from '@chakra-ui/react';
import AppModal from 'src/components/AppModal/AppModal';
import { useTranslation } from 'react-i18next';

const ParseUrlsModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  handleCreateApplications: (urls: string) => void;
}> = ({ isOpen, onClose, handleCreateApplications }) => {
  const [urls, setUrls] = useState('');
  const { t } = useTranslation('applications');
  return (
    <AppModal
      title={t('parseApplicationData')}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setUrls('');
      }}
      actions={
        <Button
          id="save-profile"
          type="submit"
          colorScheme="green"
          onClick={() => handleCreateApplications(urls)}
        >
          {t('addApplication')}
        </Button>
      }
    >
      <Stack gap={2}>
        <FormControl id="decription">
          <FormLabel>{t('urls')}</FormLabel>
          <Textarea
            rows={10}
            defaultValue={urls}
            onChange={(e) => {
              const { value } = e.target;
              setUrls(value);
            }}
            placeholder={t('pasteUrls')}
            name="description"
          />
        </FormControl>
      </Stack>
    </AppModal>
  );
};

export default React.memo(ParseUrlsModal);
