import { gql } from '@apollo/client';

export const FIND_ALL_PROMTS = gql`
  query findAllPromts {
    findAllPromts {
      id
      title
      content
    }
  }
`;
