import { client } from 'src/graphql/client';
import { FIND_ALL_COVER_LETTERS } from 'src/graphql/queries';

import { ICoverLetter, TPaginated } from 'src/types/types';

export const fetchCoverLetters = async () //   id: string,
// t: TFunction<"translation", undefined, "translation">
: Promise<ICoverLetter[] | void> => {
  const request = client.query<{
    findAllCoverLetters: TPaginated<ICoverLetter>;
  }>({
    query: FIND_ALL_COVER_LETTERS,
    variables: {
      query: ''
    }
    // fetchPolicy: 'no-cache',
  });
  return request
    .then(({ data, errors }) => {
      if (data) {
        console.log('COVER LETTERS DATA', data);
        return data.findAllCoverLetters.edges.map(({ node }) => node);
      }
      if (errors) {
        console.log('ERRORS', errors);
        // toast.error(
        //   `Something has gone wrong. ${errors
        //     .map((error) => error.message)
        //     .join('. ')}`,
        // );
      }
      return [];
    })
    .catch((error) => {
      console.log('ERROR', error);
      //   return [];
    });
};
