import React from 'react';
import { Box, Divider, Heading, Stack } from '@chakra-ui/react';
import ResetPasswordForm from './ResetPasswordForm';
import Card from '../Card/Card';
import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC = React.memo(() => {
  const { t } = useTranslation('editProfile');

  return (
    <Box as="section" py="12">
      <Box maxW="7xl" mx="auto" px={{ sm: '8' }}>
        <Card>
          <Heading size="md" mb="4">
            <Box textAlign={'center'} mt={'1.5rem'}>
              {t('reset')}
            </Box>
          </Heading>
          <Divider mb="6" />
          <Stack spacing="4">
            <ResetPasswordForm />
          </Stack>
        </Card>
      </Box>
    </Box>
  );
});

export default ResetPassword;
