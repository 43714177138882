import { gql } from '@apollo/client';

export const UPDATE_RECOMMENDATION = gql`
  mutation updateRecommendation(
    $id: ID!
    $content: String!
    $title: String
    $published: Boolean
    $company: String!
    $name: String!
    $email: String!
    $currentCompany: String!
    $currentJobTitle: String!
    $recommenderJobTitle: String!
    $recommendeeJobTitle: String!
  ) {
    updateRecommendation(
      id: $id
      data: {
        content: $content
        title: $title
        published: $published
        company: $company
        name: $name
        email: $email
        currentCompany: $currentCompany
        currentJobTitle: $currentJobTitle
        recommenderJobTitle: $recommenderJobTitle
        recommendeeJobTitle: $recommendeeJobTitle
      }
    ) {
      id
    }
  }
`;
