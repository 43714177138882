import React from 'react';

import 'driver.js/dist/driver.css';
import { Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RecommendationsTable from './RecommendationsTable';
import useAppStore from 'src/store';
import { useAppToast } from 'src/hooks/useAppToast';
import { DELETE_RECOMMENDATION } from 'src/graphql/mutations/deleteRecommendation';
import { ApolloError, useMutation } from '@apollo/client';

const RecommendationsPage: React.FC = () => {
  const { t } = useTranslation('recommendations');
  const initRecommendations = useAppStore((state) => state.initRecommendations);
  const setRecommendations = useAppStore((state) => state.setRecommendations);
  const recommendations = useAppStore((state) => state.recommendations);
  const { showToast, closeToast } = useAppToast('recommendationsPage');
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };

  const [deleteRecommendationMutation] = useMutation(DELETE_RECOMMENDATION, {
    onCompleted: (data, options) => {
      if (data.removeRecommendation) {
        setRecommendations(
          recommendations.filter((rec) => rec.id !== options?.variables?.id)
        );
        showToast({
          content: 'Recommendation succesfully removed',
          status: 'success'
        });
      }
    },
    onError
  });

  React.useEffect(() => {
    initRecommendations();
  }, [initRecommendations]);

  return (
    <Box as="section" py="1">
      <Box maxW="7xl">
        <Box w={'100%'}>
          <Heading size="lg" mb="6">
            {t('title')}
          </Heading>
          <RecommendationsTable
            handleDelete={(id) =>
              deleteRecommendationMutation({ variables: { id } })
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RecommendationsPage;
