import * as yup from 'yup';
import i18n from 'i18next';

export const validationRegistrationSchema = yup.object().shape({
  firstname: yup.string().max(16),
  lastname: yup.string().max(16),
  email: yup
    .string()
    .email()
    .max(36)
    .required(i18n.t('login:form.validation.emailRequired')),
  password: yup
    .string()
    .min(8)
    .max(32)
    .required(i18n.t('login:form.validation.passwordRequired'))
});
