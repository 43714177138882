import { gql } from '@apollo/client';

export const IMPROVE_RECOMMENDATION = gql`
  mutation improveRecommendation(
    $id: ID!
    $content: String!
    $company: String!
    $name: String!
    $email: String!
    $currentCompany: String!
    $currentJobTitle: String!
    $recommenderJobTitle: String!
    $recommendeeJobTitle: String!
  ) {
    improveRecommendation(
      id: $id
      data: {
        content: $content
        company: $company
        name: $name
        email: $email
        currentCompany: $currentCompany
        currentJobTitle: $currentJobTitle
        recommenderJobTitle: $recommenderJobTitle
        recommendeeJobTitle: $recommendeeJobTitle
      }
    ) {
      id
    }
  }
`;
