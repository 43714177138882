import { gql } from '@apollo/client';

export const UPDATE_RESUME_LANGUAGE = gql`
  mutation updateResumeLanguage($id: ID!, $language: CVLanguage!) {
    updateResumeLanguage(id: $id, language: $language) {
      id
      language
    }
  }
`;
