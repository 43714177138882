import { gql } from '@apollo/client';

export const CREATE_RECOMMENDATION = gql`
  mutation createRecommendation(
    $resumeId: ID!
    $company: String!
    $currentCompany: String!
    $currentJobTitle: String!
    $email: String!
    $name: String!
    $recommendeeJobTitle: String!
    $recommenderJobTitle: String!
  ) {
    createRecommendation(
      data: {
        resumeId: $resumeId
        company: $company
        currentCompany: $currentCompany
        currentJobTitle: $currentJobTitle
        email: $email
        name: $name
        recommendeeJobTitle: $recommendeeJobTitle
        recommenderJobTitle: $recommenderJobTitle
      }
    ) {
      id
      company
      content
      createdAt
      updatedAt
      author {
        id
        firstname
      }
      published
      recommendeeJobTitle
      recommenderJobTitle
      resume {
        id
        title
      }
      recommender {
        id
        name
      }
      isApproved
      title
    }
  }
`;
