import { gql } from '@apollo/client';

export const LINKEDIN_LOGIN = gql`
  mutation linkedInLogin($code: String!) {
    linkedInLogin(data: { code: $code }) {
      accessToken
      refreshToken
      user {
        id
        linkedInId
        firstname
        lastname
        email
      }
      changeDataToken
    }
  }
`;
