import React, {
  useRef,
  ReactNode,
  useMemo,
  FC,
  PropsWithChildren,
  memo
} from 'react';
import {
  AlertStatus,
  Flex,
  IconButton,
  Spinner,
  ToastId,
  UseToastOptions,
  useToast
} from '@chakra-ui/react';
import { CloseIcon, InfoIcon } from '@chakra-ui/icons';

type UseToastOptionsWithLoading = Omit<UseToastOptions, 'status'> & {
  status?: AlertStatus | 'loading';
};

type TToastOptions = UseToastOptionsWithLoading & {
  content?: string | ReactNode;
  update?: boolean;
};

const ToastContainer: FC<
  Pick<TToastOptions, 'status'> & PropsWithChildren<any>
> = memo(({ children, status, isClosable, onClose }) => {
  const bg: Record<string, string> = {
    loading: 'blue.400',
    error: 'red.400',
    warning: 'orange.400'
  };
  const toastIcon = useMemo(() => {
    switch (status) {
      case 'loading':
        return <Spinner />;
      case 'error':
        return <InfoIcon />;
      case 'warning':
        return <InfoIcon />;
      default:
        return null;
    }
  }, [status]);

  return (
    <Flex
      position="relative"
      color="white"
      p={3}
      bg={bg[status] || 'blue.400'}
      borderRadius={6}
      alignItems="center"
      gap={4}
    >
      {isClosable && (
        <IconButton
          position="absolute"
          top={1}
          right={1}
          colorScheme="white"
          size="xs"
          aria-label="close"
          onClick={onClose}
          icon={<CloseIcon />}
        />
      )}
      {toastIcon}
      {children}
    </Flex>
  );
});

export const useAppToast = (id: string) => {
  const toast = useToast();
  const toastRef = useRef<ToastId>();

  const closeToast = () => {
    if (toastRef.current) toast.close(toastRef.current);
  };

  const showToast = (options: TToastOptions) => {
    const { status, duration, content, update, isClosable } = options;
    if (update && toastRef.current) {
      toast.update(toastRef.current, {
        status: status as AlertStatus,
        duration,
        position: 'bottom-right',
        isClosable,
        render: () => (
          <ToastContainer
            status={status}
            isClosable={isClosable}
            onClose={closeToast}
          >
            {content}
          </ToastContainer>
        )
      });
      return;
    }
    if (id && !toast.isActive(id)) {
      toastRef.current = toast({
        id,
        status: status as AlertStatus,
        duration,
        position: 'bottom-right',
        isClosable,
        render: () => <ToastContainer status={status}>{content}</ToastContainer>
      });
    }
    if (!id) {
      toastRef.current = toast({
        id,
        status: status as AlertStatus,
        duration,
        position: 'bottom-right',
        isClosable,
        render: () => <ToastContainer status={status}>{content}</ToastContainer>
      });
    }
  };

  return { showToast, closeToast, toastRef };
};
