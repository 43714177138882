import { gql } from '@apollo/client';

export const CREATE_PROFILE = gql`
  mutation createProfile($name: String!, $content: String!) {
    createProfile(data: { name: $name, content: $content }) {
      id
      name
      content
    }
  }
`;
