import { defineStyleConfig } from '@chakra-ui/react';

export const formLabelTheme = defineStyleConfig({
  baseStyle: {
    fontSize: {
      base: 'sm',
      lg: 'md'
    }
  }
});
