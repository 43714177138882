import React, { useMemo } from 'react';
import {
  Avatar,
  Box,
  Flex,
  FlexProps,
  HStack,
  useMenuButton
} from '@chakra-ui/react';
import { HiSelector } from 'react-icons/hi';
import { IUser } from '../../types/types';

type IProps = Pick<IUser, 'firstname' | 'lastname' | 'id'> & {
  isOpen?: boolean;
};

const AccountSwitcherButton: React.FC<FlexProps & IProps> = React.memo(
  ({ firstname, lastname, id, isOpen, ...props }) => {
    const buttonProps = useMenuButton(props);
    const name = useMemo(
      () =>
        firstname || lastname
          ? `${firstname} ${lastname}`.trim()
          : '[your name]',
      [firstname, lastname]
    );

    return (
      <Flex
        as="button"
        {...buttonProps}
        w="full"
        display="flex"
        alignItems="center"
        rounded="lg"
        bg={isOpen ? 'gray.700' : 'transparent'}
        px={isOpen ? 3 : 0}
        py="2"
        fontSize="sm"
        userSelect="none"
        cursor="pointer"
        outline="0"
        transition="all 0.2s"
        _active={{ bg: isOpen ? 'gray.600' : 'transparent' }}
        _focus={{ shadow: isOpen ? 'outline' : 'none' }}
      >
        <HStack flex="1" spacing="3">
          <Avatar w="8" h="8" rounded="md" objectFit="cover" />
          {isOpen ? (
            <Box textAlign="start">
              <Box isTruncated fontWeight="semibold">
                {name}
              </Box>
              {/* <Box fontSize="11px" color="gray.400">
              ID {id}
            </Box> */}
            </Box>
          ) : null}
        </HStack>
        {isOpen ? (
          <Box fontSize="lg" color="gray.400">
            <HiSelector />
          </Box>
        ) : null}
      </Flex>
    );
  }
);

export default AccountSwitcherButton;
