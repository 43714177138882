import { gql } from '@apollo/client';

export const FIND_ALL_RECOMMENDATIONS = gql`
  query findAllRecommendations(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $query: String
  ) {
    findAllRecommendations(
      first: $first
      after: $after
      last: $last
      before: $before
      query: $query
    ) {
      edges {
        node {
          id
          company
          content
          createdAt
          updatedAt
          author {
            id
            firstname
          }
          published
          recommendeeJobTitle
          recommenderJobTitle
          resume {
            id
            title
          }
          recommender {
            id
            name
          }
          isApproved
          title
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
