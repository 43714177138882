import React, { RefObject, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IProps {
  submitCallback?: any;
  title: string;
  body?: string | React.ReactChild;
  submitText?: string;
  submitColorScheme?: string;
  cancelText?: string;
  children?: React.ReactNode;
}

const Alert: React.FC<IProps> = React.memo(
  ({
    title,
    body,
    submitCallback,
    submitText,
    cancelText,
    submitColorScheme,
    children,
    ...props
  }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation('common');
    const cancelRef: RefObject<any> = useRef();
    const submitCallbackWithClose = async () => {
      await onClose();
      await submitCallback();
    };
    return (
      <>
        <Box onClick={onOpen} {...props}>
          {children}
        </Box>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
          size={'2xl'}
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>{title}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>{body}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancelText || t('cancel')}
              </Button>
              <Button
                ref={cancelRef}
                colorScheme={submitColorScheme}
                ml={3}
                type={'submit'}
                onClick={submitCallbackWithClose}
              >
                {submitText || t('ok')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  }
);

export default Alert;
