import React from 'react';
import { Box, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react';
import { FieldProps } from 'formik';

interface IProps {
  label: string | React.ReactElement;
  direction?: 'column' | 'row';
  tabIndex?: number;
  textarea?: boolean;
}

const ValidatedInput: React.FC<IProps & FieldProps> = React.memo(
  ({
    field,
    form: { touched, errors, setFieldTouched },
    label,
    direction,
    tabIndex,
    textarea,
    ...props
  }) => {
    return (
      <FormControl
        {...(direction
          ? {
              mx: 'auto',
              display: 'flex',
              flexDirection: direction,
              justifyContent: 'center'
            }
          : {})}
      >
        <FormLabel minW="fit-content" mb={2}>
          {label}
        </FormLabel>
        {textarea ? (
          <Textarea
            tabIndex={tabIndex}
            {...field}
            {...props}
            isInvalid={!!errors[field.name]}
            onFocus={() => {
              setFieldTouched(field.name, false);
            }}
          />
        ) : (
          <Input
            tabIndex={tabIndex}
            {...field}
            {...props}
            onFocus={() => {
              setFieldTouched(field.name, false);
            }}
            isInvalid={!!errors[field.name]}
          />
        )}
        {touched[field.name] && errors[field.name] && (
          <Box
            width={'100%'}
            color="red.500"
            sx={{ '&::first-letter': { textTransform: 'capitalize' } }}
          >
            {errors[field.name] as string}
          </Box>
        )}
      </FormControl>
    );
  }
);

export default ValidatedInput;
