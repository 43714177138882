import React from 'react';

import 'driver.js/dist/driver.css';
import { Box, Flex, Heading } from '@chakra-ui/react';
import CVsTable from './CVsTable';
import { useTranslation } from 'react-i18next';
import { useCVs } from 'src/hooks';

const MyCvsPage: React.FC = () => {
  const { t } = useTranslation('myCVs');
  const { handleDefaultResume, handleRowClick, handleDeleteResume } = useCVs();

  return (
    <Box as="section" py="1">
      <Flex justifyContent="space-between">
        <Heading size="lg" mb="6">
          {t('title')}
        </Heading>
      </Flex>
      <CVsTable
        setAsDefault={handleDefaultResume}
        handleDelete={handleDeleteResume}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};

export default MyCvsPage;
