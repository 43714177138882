import { gql } from '@apollo/client';

export const FIND_RESUME_RECOMMENDATIONS = gql`
  query findResumeRecommendations($resumeId: String!) {
    findResumeRecommendations(resumeId: $resumeId) {
      id
      createdAt
      updatedAt
      title
      content
      published
    }
  }
`;
