import React from 'react';
import { Text, TextProps, useColorModeValue } from '@chakra-ui/react';

const NavHeading: React.FC<TextProps> = React.memo((props) => (
  <Text
    as="h4"
    fontSize="xs"
    fontWeight="semibold"
    px="2"
    lineHeight="1.25"
    color={useColorModeValue('whiteAlpha.800', 'gray.400')}
    {...props}
  />
));

export default NavHeading;
