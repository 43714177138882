import React from 'react';
import TemplateCard, { TemplateCardProps } from '../TemplateCard/TemplateCard';
import { Box, Flex, Button, BoxProps } from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

const TemplateCollection: React.FC<
  BoxProps & { css: string; templates: TemplateCardProps[] }
> = ({ css, templates, ...otherProps }) => {
  const { t } = useTranslation('createCV');

  return (
    <Box overflow={'auto'} {...otherProps}>
      <Box
        fontSize="2xl"
        fontWeight="semibold"
        textAlign={'center'}
        as="h4"
        lineHeight="tight"
        pb={'2rem'}
      >
        {t('template.title')}:
      </Box>
      <Flex wrap={'wrap'} justifyContent={'center'}>
        {templates.map((tmp) => {
          if (css === tmp.id) {
            return (
              <Button
                border={'3px'}
                borderColor={'blue.600'}
                borderRadius={'0.375rem'}
                p={0}
                variant={'outline'}
                height={'auto'}
                key={tmp.id}
                m={'.25rem'}
                id={tmp.id}
                colorScheme="blue"
                backgroundColor={'blue.50'}
              >
                <label>
                  <Field type="radio" name="css" value={tmp.id} hidden />
                  <TemplateCard
                    name={tmp.name}
                    img={tmp.img}
                    id={tmp.id}
                    key={tmp.id}
                  />
                </label>
              </Button>
            );
          }
          return (
            <Button
              border={0}
              variant={'outline'}
              height={'auto'}
              key={tmp.id}
              id={tmp.id}
              m={'.25rem'}
              p={0}
            >
              <label>
                <Field type="radio" name="css" value={tmp.id} hidden />
                <TemplateCard
                  name={tmp.name}
                  img={tmp.img}
                  id={tmp.id}
                  key={tmp.id}
                />
              </label>
            </Button>
          );
        })}
      </Flex>
    </Box>
  );
};

export default TemplateCollection;
