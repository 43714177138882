import { gql } from '@apollo/client';

export const FIND_ALL_APPLICATIONS = gql`
  query findAllApplications(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $query: String
  ) {
    findAllApplications(
      first: $first
      after: $after
      last: $last
      before: $before
      query: $query
    ) {
      edges {
        node {
          id
          company
          coverLetter {
            id
            title
            content
          }
          resume {
            id
            title
          }
          createdAt
          description
          postDate
          title
          updatedAt
          url
          user {
            id
            firstname
            lastname
          }
          validDate
          status
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
