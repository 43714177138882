import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { breadcrumbAnatomy } from '@chakra-ui/anatomy';
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);
const baseStyle = definePartsStyle({
  container: {
    fontSize: 'sm',
    fontWeight: 'semibold'
  }
});

export const breadcrumbTheme = defineMultiStyleConfig({ baseStyle });
