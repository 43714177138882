import { gql } from '@apollo/client';

export const IMPROVE_RESUME = gql`
  mutation improveResume(
    $id: ID!
    $versionId: ID!
    $content: String
    $selectedPromtId: Int
  ) {
    improveResume(
      data: {
        id: $id
        versionId: $versionId
        content: $content
        selectedPromtId: $selectedPromtId
      }
    ) {
      id
      content
      language
      versions {
        id
        createdAt
        content
        tag
      }
    }
  }
`;
