import React from 'react';
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef
} from '@tanstack/react-table';

import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';

import ReactTable from 'src/components/Table/ReactTable';
import { AutoCompleteField } from 'src/components/ui';
import useAppStore from 'src/store';
import { IApplication, EApplicationStatus } from 'src/types/types';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Link as ChakraLink,
  useBreakpointValue,
  Stack,
  Icon,
  Box
} from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Alert from 'src/components/Alert/Alert';
import { HiOutlineDotsVertical, HiPlus } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { GoDotFill } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const StatusSelector: React.FC<{
  id: string;
  handleStatusUpdate: (id: string, status: EApplicationStatus) => void;
  value: EApplicationStatus;
  isLoading?: boolean;
}> = React.memo(({ id, handleStatusUpdate, value, isLoading }) => {
  const StatusColor: Record<EApplicationStatus, string> = React.useMemo(
    () => ({
      CREATED: 'gray.400',
      APPLIED: 'yellow.500',
      INVITED: 'blue.400',
      DECLINED: 'red.400',
      APPROVED: 'green.400'
    }),
    []
  );

  return (
    <Box w="fit-content">
      <Menu>
        <MenuButton
          isDisabled={isLoading}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          leftIcon={<Icon as={GoDotFill} color={StatusColor[value]} />}
          bg="transparent"
          p={0}
          _hover={{
            bg: 'transparent'
          }}
          _active={{
            bg: 'transparent'
          }}
          sx={{
            '& span:first-child': {
              mr: 0.5
            },
            '& span:last-child': {
              opacity: 0,
              transition: 'opacity 0.3s linear,opacity 0.3s linear'
            },
            '&:hover span:last-child': {
              opacity: isLoading ? 0 : 1
            }
          }}
          fontSize="sm"
          textTransform="capitalize"
        >
          {value.toLowerCase()}
        </MenuButton>
        <MenuList border="none" w="fit-content" minW="fit-content">
          {Object.keys(StatusColor).map((key: EApplicationStatus) => (
            <MenuItem
              key={key}
              fontSize="sm"
              px={1}
              onClick={() => {
                handleStatusUpdate(id, key);
              }}
              textTransform="capitalize"
            >
              <Icon as={GoDotFill} color={StatusColor[key]} />
              {key.toLowerCase()}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
});

const CoverLetterComponent: React.FC<{
  value?: {
    id: string;
    title: string;
  };
  onCoverLetterCreate: () => void;
}> = React.memo(({ value, onCoverLetterCreate }) => {
  const { t } = useTranslation('coverLetters');
  return value ? (
    <ChakraLink
      fontSize="sm"
      fontWeight={500}
      as={Link}
      to={`/cover-letters/${value.id}`}
    >
      {value.title}
    </ChakraLink>
  ) : (
    <Button leftIcon={<HiPlus />} size="xs" onClick={onCoverLetterCreate}>
      {t('create')}
    </Button>
  );
});

export const ApplicationsTable: React.FC<{
  onApplicationEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  onCoverLetterSelect: (
    applicationId: string,
    coverLetterId: string,
    title?: string
  ) => void;
  onResumeSelect: (applicationId: string, resumeId: string) => void;
  handleStatusUpdate: (id: string, status: EApplicationStatus) => void;
}> = ({
  onApplicationEdit,
  handleDelete,
  onCoverLetterSelect,
  onResumeSelect,
  handleStatusUpdate
}) => {
  const columnVisibility:
    | {
        title: boolean;
        status: boolean;
        coverLetter: boolean;
      }
    | undefined = useBreakpointValue({
    base: {
      title: false,
      status: false,
      coverLetter: false
    },
    sm: {
      title: false,
      status: false,
      coverLetter: true
    },
    md: {
      title: true,
      status: true,
      coverLetter: true
    }
  });
  const { t } = useTranslation('applications');
  const resumes = useAppStore((state) => state.resumes);
  const applications = useAppStore((state) => state.applications);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState('');

  const columns = React.useMemo<ColumnDef<IApplication, any>[]>(
    () => [
      {
        accessorKey: 'company',
        header: () => 'Company',
        cell: (props) => {
          const value = props.getValue();
          return (
            <Stack>
              <Text fontWeight={500}>{value}</Text>
              {!columnVisibility?.title && (
                <Text fontSize="sm">{props.row.original.title}</Text>
              )}
              {!columnVisibility?.status && (
                <StatusSelector
                  id={props.row.original.id}
                  handleStatusUpdate={handleStatusUpdate}
                  value={props.row.original.status}
                />
              )}
            </Stack>
          );
        }
      },
      {
        accessorKey: 'title',
        header: () => 'Title'
      },
      {
        accessorKey: 'resume',
        header: () => 'CVs',
        enableColumnFilter: false,
        cell: (props) => {
          const value = props.getValue();
          return (
            <AutoCompleteField
              defaultValue={value?.title}
              handleChange={console.log}
              options={resumes.map(({ id, title }) => ({
                id,
                name: title || id,
                value: id
              }))}
              hasAddItem
              onSelectOption={({ item }) =>
                onResumeSelect(props.row.original.id, item.value)
              }
            />
          );
        }
      },
      {
        accessorKey: 'coverLetter',
        header: () => 'Cover Letter',
        enableColumnFilter: false,
        cell: (props) => {
          const value: { id: string; title: string } | undefined =
            props.getValue();

          return (
            <CoverLetterComponent
              value={value}
              onCoverLetterCreate={() =>
                onCoverLetterSelect(
                  props.row.original.id,
                  'new',
                  props.row.original.company
                )
              }
            />
          );
        }
      },
      {
        accessorKey: 'status',
        cell: (props) => {
          const value: EApplicationStatus =
            props.getValue() ?? EApplicationStatus.CREATED;
          return (
            <StatusSelector
              id={props.row.original.id}
              handleStatusUpdate={handleStatusUpdate}
              value={value}
            />
          );
        }
      },
      {
        accessorKey: 'id',
        header: () => null,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => {
          const id = props.getValue();
          return (
            <Menu direction={'ltr'}>
              <MenuButton
                as={IconButton}
                icon={<HiOutlineDotsVertical />}
                variant="ghost"
                _hover={{
                  bg: 'gray.300'
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList p={0}>
                <MenuItem
                  p="0"
                  as={() => (
                    <Button
                      leftIcon={<EditIcon />}
                      variant="ghost"
                      colorScheme="blue"
                      w="100%"
                      onClick={() => onApplicationEdit(id)}
                    >
                      {t('edit')}
                    </Button>
                  )}
                />
                {!columnVisibility?.coverLetter && (
                  <>
                    <MenuDivider m={0} />
                    <MenuItem>
                      <CoverLetterComponent
                        value={props.row.original?.coverLetter}
                        onCoverLetterCreate={() =>
                          onCoverLetterSelect(
                            props.row.original.id,
                            'new',
                            props.row.original.company
                          )
                        }
                      />
                    </MenuItem>
                  </>
                )}
                <MenuDivider m={0} />
                <MenuItem
                  as={() => (
                    <Alert
                      title={t('deleteApplication')}
                      body={t('deleteApplicationConfirmation')}
                      submitText={t('modal.confirm')}
                      cancelText={t('modal.cancel')}
                      submitColorScheme={'red'}
                      submitCallback={() => handleDelete(id)}
                    >
                      <Button
                        leftIcon={<DeleteIcon />}
                        variant="ghost"
                        colorScheme="red"
                        w="100%"
                      >
                        {t('modal.delete')}
                      </Button>
                    </Alert>
                  )}
                  p="0"
                />
              </MenuList>
            </Menu>
          );
        }
      }
    ],
    [
      columnVisibility,
      handleDelete,
      handleStatusUpdate,
      onApplicationEdit,
      onCoverLetterSelect,
      onResumeSelect,
      resumes
    ]
  );

  const table = useReactTable<IApplication>({
    data: applications,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility
      // pagination: {
      //   pageIndex: 0,
      //   pageSize: 6,
      // },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  });

  return (
    <ReactTable<IApplication>
      table={table}
      searchValue={globalFilter}
      onSearchHandler={(value) => setGlobalFilter(String(value))}
    />
  );
};

export default React.memo(ApplicationsTable);
