import enMain from './en/main.json';
import enNavbar from './en/navbar.json';
import enCommon from './en/common.json';
import enMyCVs from './en/myCVs.json';
import enRecommendations from './en/recommendations.json';
import enCoverLetters from './en/coverLetters.json';
import enApplications from './en/applications.json';
import enCV from './en/cv.json';
import enCreateCV from './en/createCV.json';
import enLogin from './en/login.json';
import enProfiles from './en/profiles.json';
import enEditAccount from './en/editProfile.json';
import enMainTour from './en/tours/mainTour.json';
import enProfilesTour from './en/tours/profilesTour.json';
import enCVTour from './en/tours/cvTour.json';
import enSubscriptions from './en/subscriptions.json';

import esMain from './es/main.json';
import esNavbar from './es/navbar.json';
import esCommon from './es/common.json';
import esMyCVs from './es/myCVs.json';
import esCV from './es/cv.json';
import esCreateCV from './es/createCV.json';
import esLogin from './es/login.json';
import esProfiles from './es/profiles.json';
import esEditAccount from './es/editProfile.json';
import esMainTour from './es/tours/mainTour.json';
import esProfilesTour from './es/tours/profilesTour.json';
import esCVTour from './es/tours/cvTour.json';
import esSubscriptions from './es/subscriptions.json';

import frMain from './fr/main.json';
import frNavbar from './fr/navbar.json';
import frCommon from './fr/common.json';
import frMyCVs from './fr/myCVs.json';
import frCV from './fr/cv.json';
import frCreateCV from './fr/createCV.json';
import frLogin from './fr/login.json';
import frProfiles from './fr/profiles.json';
import frEditAccount from './fr/editProfile.json';
import frMainTour from './fr/tours/mainTour.json';
import frProfilesTour from './fr/tours/profilesTour.json';
import frCVTour from './fr/tours/cvTour.json';
import frSubscriptions from './fr/subscriptions.json';

const resources = {
  en: {
    common: enCommon,
    main: enMain,
    navbar: enNavbar,
    myCVs: enMyCVs,
    recommendations: enRecommendations,
    coverLetters: enCoverLetters,
    applications: enApplications,
    cv: enCV,
    createCV: enCreateCV,
    login: enLogin,
    profiles: enProfiles,
    editProfile: enEditAccount,
    mainTour: enMainTour,
    profilesTour: enProfilesTour,
    cvTour: enCVTour,
    subscriptions: enSubscriptions
  },
  es: {
    common: esCommon,
    main: esMain,
    navbar: esNavbar,
    myCVs: esMyCVs,
    // recommendations: esRecommendations,
    // coverLetters: esCoverLetters,
    // applications: esApplications,
    cv: esCV,
    createCV: esCreateCV,
    login: esLogin,
    profiles: esProfiles,
    editProfile: esEditAccount,
    mainTour: esMainTour,
    profilesTour: esProfilesTour,
    cvTour: esCVTour,
    subscriptions: esSubscriptions
  },
  fr: {
    common: frCommon,
    main: frMain,
    navbar: frNavbar,
    myCVs: frMyCVs,
    // recommendations: frRecommendations,
    // coverLetters: frCoverLetters,
    // applications: frApplications,
    cv: frCV,
    createCV: frCreateCV,
    login: frLogin,
    profiles: frProfiles,
    editProfile: frEditAccount,
    mainTour: frMainTour,
    profilesTour: frProfilesTour,
    cvTour: frCVTour,
    subscriptions: frSubscriptions
  }
};

export default resources;
