import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateProfile($id: ID!, $name: String, $content: String) {
    updateProfile(data: { id: $id, name: $name, content: $content }) {
      id
      name
      content
    }
  }
`;
