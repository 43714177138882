import React from 'react';
import { Icon, IconProps, useToken } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const SmallLogo = React.memo((props: IconProps & { iconColor?: string }) => {
  const navigate = useNavigate();
  const { iconColor = 'currentColor', ...restProps } = props;
  const color = useToken('colors', iconColor);
  return (
    <Icon
      width="31"
      height="31"
      viewBox="0 0 31 31"
      color="red.500"
      onClick={() => navigate('/')}
      cursor="pointer"
      {...restProps}
    >
      <path
        d="M 8.947 21.757 L 17.516 21.757 L 17.516 13.187 L 8.947 13.187 L 8.947 21.757 Z"
        fill={color}
      />
      <path
        d="M 8.947 30.336 L 17.516 30.336 L 17.516 21.766 L 8.947 21.766 L 8.947 30.336 Z"
        fill={color}
      />
      <path
        d="M 0.368 21.757 L 8.937 21.757 L 8.937 13.187 L 0.368 13.187 L 0.368 21.757 Z"
        fill={color}
      />
      <path
        d="M 21.799 8.905 L 30.368 8.905 L 30.368 0.336 L 21.799 0.336 L 21.799 8.905 Z"
        fill={color}
      />
      <path
        d="M 21.799 17.484 L 30.368 17.484 L 30.368 8.914 L 21.799 8.914 L 21.799 17.484 Z"
        fill={color}
      />
      <path
        d="M 13.236 8.905 L 21.806 8.905 L 21.806 0.336 L 13.236 0.336 L 13.236 8.905 Z"
        fill={color}
      />
    </Icon>
  );
});

export default SmallLogo;
