import { gql } from '@apollo/client';

export const CREATE_RESUME = gql`
  mutation createResume(
    $language: String!
    $content: String
    $title: String
    $css: String
    $industry: String
    $company: String
    $job: String
    $description: String
  ) {
    createResume(
      data: {
        language: $language
        content: $content
        title: $title
        css: $css
        industry: $industry
        company: $company
        job: $job
        description: $description
      }
    ) {
      id
      content
      title
      industry
      company
      job
      description
      versions {
        id
        createdAt
        content
        tag
      }
      language
    }
  }
`;
