import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthStore } from 'src/features/auth/model/authStore';

const excludedRoutes = ['/auth', '/auth/linkedin', '/auth/password_recovery'];

const withAuth = (Component: React.FC) => (props: any) => {
  const { isAuth } = useAuthStore();
  const location = useLocation();
  const currentPath = location.pathname;

  const isExcludedRoute = excludedRoutes.includes(currentPath);

  if (isAuth && isExcludedRoute) {
    return <Navigate to="/" />;
  }

  if (!isAuth && !isExcludedRoute) {
    return <Navigate to="/auth" />;
  }

  return <Component {...props} />;
};

export default withAuth;
