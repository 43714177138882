import { gql } from '@apollo/client';

export const GET_RESUME_BY_ID = gql`
  query findOneResume($id: String!) {
    findOneResume(id: $id) {
      id
      content
      title
      language
      html
      published
      company
      industry
      job
      description
      profile {
        id
      }
      versions {
        id
        createdAt
        content
        tag
      }
      isDefault
    }
  }
`;
