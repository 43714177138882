import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import { breadcrumbTheme } from './breadcrumb';
import { formLabelTheme } from './formLabel';
import { inputTheme } from './input';
import { drawerTheme } from 'src/shared/config/theme/drawer';

export const theme = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme.colors
  },
  components: {
    ...baseTheme.components,
    Breadcrumb: breadcrumbTheme,
    FormLabel: formLabelTheme,
    Input: inputTheme,
    Drawer: drawerTheme
  }
});
