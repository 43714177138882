import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import Editor, { Monaco } from '@monaco-editor/react';
import React, { useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

type EditorProps = {
  value: string | null;
  onChange: (value: string) => void;
  handleEditorDidMount?: (
    editor: monaco.editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) => void;
};

const monacoEditorOptions: monaco.editor.IStandaloneEditorConstructionOptions =
  {
    minimap: {
      enabled: false
    },
    fontSize: 16,
    renderLineHighlight: 'none',
    scrollbar: {
      useShadows: false,
      vertical: 'hidden',
      alwaysConsumeMouseWheel: false
    },
    wordWrap: 'on',
    useTabStops: false,
    overviewRulerLanes: 0,
    hideCursorInOverviewRuler: true,
    overviewRulerBorder: false,
    scrollBeyondLastLine: false,
    stickyScroll: {
      enabled: true
    },
    automaticLayout: true,
    unusualLineTerminators: 'off',
    wordBasedSuggestions: false,
    glyphMargin: false,
    folding: false,
    lineNumbers: 'off',
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 0
  };

const MarkdownEditor = ({
  value,
  onChange,
  handleEditorDidMount
}: EditorProps) => {
  const [width, setWidth] = useState(0);
  const isEditorResizable = useBreakpointValue({
    base: false,
    lg: true,
    '2xl': false
  });

  useEffect(() => {
    const updateWidth = () => setWidth(window.innerWidth - 500);
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <Editor
      width={isEditorResizable && width ? width : '100%'}
      defaultLanguage="markdown"
      value={value ?? ''}
      onChange={onChange}
      options={monacoEditorOptions}
      onMount={handleEditorDidMount}
    />
  );
};

export default MarkdownEditor;
