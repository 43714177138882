import React from 'react';

import { Box, BoxProps, Flex, Heading, IconButton } from '@chakra-ui/react';
import { RiArrowDownSLine } from 'react-icons/ri';

export const SidebarElementsContainer: React.FC<
  BoxProps & {
    title: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }
> = ({ title, children, isOpen, setIsOpen, ...otherProps }) => (
  <Box
    id="CV-options"
    color={'gray.900'}
    borderRadius="md"
    borderColor="gray.200"
    borderStyle="solid"
    borderWidth={1}
    h={isOpen ? '680px' : '10'}
    p={2}
    transition="all"
    maxH="full"
    transform="auto"
    transitionDuration="500ms"
    transitionTimingFunction="ease-in-out"
    overflowY="hidden"
    mt={{ base: 4, lg: 0 }}
    {...otherProps}
  >
    <Flex
      onClick={() => setIsOpen((prev) => !prev)}
      justify="space-between"
      alignItems="center"
      cursor="pointer"
      pb={3}
    >
      <Heading as="h4" size="sm" pl={2} fontWeight={500}>
        {title}
      </Heading>
      <IconButton
        aria-label="arrow-button"
        h={3}
        _active={{
          background: 'transparent'
        }}
        _hover={{
          background: 'transparent'
        }}
        rotate={isOpen ? 180 : 0}
        bg="transparent"
        icon={<RiArrowDownSLine color="black" width={20} height={20} />}
        transform="auto"
        transition="all"
        transitionDuration="500ms"
        transitionTimingFunction="ease-in-out"
      />
    </Flex>
    <Box
      h="full"
      transition="all"
      transitionDuration="500ms"
      transitionTimingFunction="ease-in-out"
      opacity={isOpen ? 100 : 0}
      px={2}
    >
      {children}
    </Box>
  </Box>
);
