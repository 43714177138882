import { gql } from '@apollo/client';

export const CREATE_APPLICATION = gql`
  mutation createApplication(
    $title: String
    $description: String
    $company: String
    $url: String
    $postDate: Date
    $validDate: Date
  ) {
    createApplication(
      data: {
        title: $title
        description: $description
        company: $company
        url: $url
        postDate: $postDate
        validDate: $validDate
      }
    ) {
      id
      company
      coverLetter {
        id
        title
        content
      }
      description
      postDate
      title
      url
      validDate
      resume {
        id
        title
        description
      }
    }
  }
`;
