import { create } from 'zustand';
import { IUser } from 'src/types/types';

interface IAuthState {
  planName: string;
  user: IUser | null;
  isAuth: boolean;
}

interface IAuthActions {
  setPlanName: (planName: string) => void;
  setUser: (user: IUser | null) => void;
  setIsAuth: (isAuth: boolean) => void;
}

export const useAuthStore = create<IAuthState & IAuthActions>()((set) => ({
  isAuth: !!localStorage.getItem('auth'),
  planName: 'free',
  user: null,
  setPlanName: (planName: string) => set({ planName }),
  setUser: (user: IUser | null) => set({ user }),
  setIsAuth: (isAuth: boolean) => set({ isAuth })
}));
