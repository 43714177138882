import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMainPageTour } from './useTours';
import useAppStore from 'src/store';
import { ApolloError, useMutation } from '@apollo/client';
import { useAppToast } from './useAppToast';
import { SET_RESUME_DEFAULT } from 'src/graphql/mutations';
import { useAuthStore } from 'src/features/auth/model/authStore';

export const useCVs = () => {
  const nav = useNavigate();
  const { user } = useAuthStore();
  const { mainPageTour } = useMainPageTour();
  const initResumes = useAppStore((state) => state.initResumes);
  const resumes = useAppStore((state) => state.resumes);
  const setResumes = useAppStore((state) => state.setResumes);
  const deleteResume = useAppStore((state) => state.deleteResume);
  const { showToast, closeToast } = useAppToast('myCVsPage');
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };
  const [setDefaultResume] = useMutation(SET_RESUME_DEFAULT, {
    onCompleted: (data, options) => {
      if (data.updateResume.isDefault) {
        setResumes(
          resumes.map((cv) =>
            cv.id !== options?.variables?.id
              ? { ...cv, isDefault: false }
              : { ...cv, isDefault: true }
          )
        );
        showToast({
          content: 'Default resume successfully updated',
          status: 'success'
        });
      }
    },
    onError
  });

  const handleDefaultResume = (id: string) =>
    setDefaultResume({
      variables: {
        id
      }
    });

  const handleDeleteResume = (id: string) => {
    deleteResume(id);
  };

  const handleRowClick = (id: string) => nav(`/my-cvs/${id}`);

  React.useEffect(() => {
    if (user && !user.isMainTourPassed) {
      mainPageTour.drive();
    }
  }, [user]);

  React.useEffect(() => {
    initResumes();
  }, [initResumes]);

  return { handleDefaultResume, handleRowClick, handleDeleteResume };
};
