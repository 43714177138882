import { client } from 'src/graphql/client';
import { DELETE_RESUME } from 'src/graphql/mutations/deleteResume';
import { FIND_ALL_RESUMES } from 'src/graphql/queries';
import { IResume, TPaginated } from 'src/types/types';
import { onErrorToastHandler, onErrorsToastHandler } from '../toasts';
import { UPDATE_RESUME } from 'src/graphql/mutations';
import { TCreateResumeInput, TUpdateResumeInput } from './types';
import { CREATE_RESUME } from 'src/graphql/mutations/createCV';

export const fetchResumes = async (
  reset?: boolean
): Promise<IResume[] | void> => {
  const request = client.query<{
    findAllResumes: TPaginated<IResume>;
  }>({
    query: FIND_ALL_RESUMES,
    variables: {
      query: ''
    },
    fetchPolicy: 'network-only'
  });
  return request
    .then(({ data, errors }) => {
      if (errors) {
        onErrorsToastHandler(errors);
        return;
      }
      if (data) {
        return data.findAllResumes.edges.map(({ node }) => node);
      }
      return;
    })
    .catch(onErrorToastHandler);
};

export const fetchDeleteResume = async (
  id: string
): Promise<boolean | void> => {
  const request = client.mutate<{
    removeResume: boolean;
  }>({
    mutation: DELETE_RESUME,
    variables: {
      id
    }
  });
  return request
    .then(({ data, errors }) => {
      if (errors) {
        onErrorsToastHandler(errors);
        return false;
      }
      if (data) {
        return data.removeResume;
      }
      return false;
    })
    .catch(onErrorToastHandler);
};

export const fetchUpdateResume = async (
  data: TUpdateResumeInput
): Promise<IResume | void> => {
  const request = client.mutate<{
    updateResume: IResume;
  }>({
    mutation: UPDATE_RESUME,
    variables: {
      ...data
    }
  });
  return request
    .then(({ data, errors }) => {
      if (errors) {
        onErrorsToastHandler(errors);
        return;
      }
      if (data) {
        return data.updateResume;
      }
      return;
    })
    .catch(onErrorToastHandler);
};

export const fetchCreateResume = async (
  data: TCreateResumeInput
): Promise<IResume | void> => {
  const request = client.mutate<{
    createResume: IResume;
  }>({
    mutation: CREATE_RESUME,
    variables: {
      ...data
    }
  });
  return request
    .then(({ data, errors }) => {
      if (errors) {
        onErrorsToastHandler(errors);
        return;
      }
      if (data) {
        return data.createResume;
      }
      return;
    })
    .catch(onErrorToastHandler);
};
