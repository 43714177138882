import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser(
    $firstname: String
    $lastname: String
    $email: String
    $isMainTourPassed: Boolean
    $isProfileTourPassed: Boolean
    $isCvTourPassed: Boolean
  ) {
    updateUser(
      data: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        isMainTourPassed: $isMainTourPassed
        isProfileTourPassed: $isProfileTourPassed
        isCvTourPassed: $isCvTourPassed
      }
    ) {
      id
      linkedInId
      firstname
      lastname
      email
    }
  }
`;
