import { gql } from '@apollo/client';

export const UPDATE_APPLICATION = gql`
  mutation updateApplication(
    $id: ID!
    $title: String
    $description: String
    $company: String
    $url: String
    $postDate: Date
    $validDate: Date
  ) {
    updateApplication(
      id: $id
      data: {
        title: $title
        description: $description
        company: $company
        url: $url
        postDate: $postDate
        validDate: $validDate
      }
    ) {
      id
      company
      coverLetter {
        id
        title
        content
      }
      resume {
        id
        title
      }
      description
      postDate
      title
      url
      validDate
    }
  }
`;

export const UPDATE_APPLICATION_RESUME = gql`
  mutation updateApplication($id: ID!, $resumeId: String) {
    updateApplication(id: $id, data: { resumeId: $resumeId }) {
      id
      resume {
        id
        title
      }
    }
  }
`;

export const UPDATE_APPLICATION_STATUS = gql`
  mutation updateApplication($id: ID!, $status: String) {
    updateApplication(id: $id, data: { status: $status }) {
      id
      status
    }
  }
`;
