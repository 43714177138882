import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { drawerAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(drawerAnatomy.keys);
const baseStyle = definePartsStyle({
  overlay: {
    size: 'xs'
  },
  closeButton: {
    color: 'white',
    bg: 'blue.500',
    _hover: {
      bg: 'blue.600'
    },
    _active: {
      bg: 'blue.700'
    },
    rounded: '0'
  }
});

export const drawerTheme = defineMultiStyleConfig({ baseStyle });
