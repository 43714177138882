import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { ApolloError, useMutation } from '@apollo/client';
import {
  CREATE_APPLICATION,
  CREATE_APPLICATIONS,
  CREATE_COVER_LETTER,
  DELETE_APPLICATION,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_RESUME,
  UPDATE_APPLICATION_STATUS
} from 'src/graphql/mutations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EApplicationStatus, IApplication } from 'src/types/types';
import useAppStore from 'src/store';
import { useAppToast } from 'src/hooks/useAppToast';
import { useLoading } from 'src/shared/ui';

export const useApplications = () => {
  const initCoverLetters = useAppStore((state) => state.initCoverLetters);
  const initResumes = useAppStore((state) => state.initResumes);
  const initApplications = useAppStore((state) => state.initApplications);
  const setApplications = useAppStore((state) => state.setApplications);
  const applications = useAppStore((state) => state.applications);
  const [loading, setLoading] = useState(true);
  const { setLoading: setIsLoading } = useLoading();
  const [searchParams, setSearchParams] = useSearchParams();
  const application: Partial<IApplication> = useMemo(() => {
    const selectedApp = applications.find(
      (app) => app.id === searchParams.get('id')
    );
    return selectedApp ?? { url: '', company: '', title: '', description: '' };
  }, [applications, searchParams]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isMultipleOpen,
    onOpen: onMultipleOpen,
    onClose: onMultipleClose
  } = useDisclosure();
  const nav = useNavigate();
  const setModalOpen = useAppStore((state) => state.setCreateModalState);
  const { showToast, closeToast } = useAppToast('coverLetters');
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };

  const [createCoverLetterMutation] = useMutation(CREATE_COVER_LETTER, {
    onCompleted: async (data) => {
      await closeToast();
      if (data.createCoverLetter) {
        setApplications(
          applications.map((app) =>
            app.id === data.createCoverLetter.applicationId
              ? { ...app, coverLetter: data.createCoverLetter }
              : app
          )
        );
        nav(`/cover-letters/${data.createCoverLetter.id}`);
      }
    },
    onError
  });
  const [createApplicationMutation, { loading: createApplicationLoading }] =
    useMutation(CREATE_APPLICATION, {
      onCompleted: (data) => {
        setApplications([data.createApplication, ...applications]);
        onClose();
      },
      onError: (error) => {
        console.log('CREATE APPLICATION: ', error);
        onClose();
      }
    });
  const [createApplicationsMutation] = useMutation(CREATE_APPLICATIONS, {
    onCompleted: (data) => {
      setApplications([...data.createApplications, ...applications]);
      showToast({
        content: 'Applications successfulle created',
        status: 'success',
        update: true
      });
    },
    onError
  });
  const [updateApplicationMutation, { loading: updateApplicationLoading }] =
    useMutation(UPDATE_APPLICATION, {
      onCompleted: (data) => {
        // setApplications([...applications, data.createApplication]);
        setApplications(
          applications.map((a) =>
            a.id === data.updateApplication.id
              ? { ...a, ...data.updateApplication }
              : a
          )
        );
        onClose();
      },
      onError: (error) => {
        console.log('UPDATE APPLICATION ERROR: ', error);
        onClose();
      }
    });
  const [deleteApplicationMutation] = useMutation(DELETE_APPLICATION);
  const [selectApplicationResumeMutation] = useMutation(
    UPDATE_APPLICATION_RESUME
  );
  const [updateApplicationStatusMutation] = useMutation(
    UPDATE_APPLICATION_STATUS,
    {
      onCompleted: (data) => {
        if (data.updateApplication) {
          const { id, status } = data.updateApplication;
          setApplications(
            applications.map((app) =>
              app.id === id ? { ...app, status } : app
            )
          );
        }
      },
      onError
    }
  );

  const handleSubmit = async ({
    id,
    url,
    company,
    title,
    description
  }: Partial<IApplication>) => {
    if (id) {
      await updateApplicationMutation({
        variables: {
          id: application.id,
          title,
          description,
          company,
          url
        }
      });
    } else {
      await createApplicationMutation({
        variables: {
          title,
          description,
          company,
          url
        }
      });
    }
    // setSelectedApplication(defaultApplicationsValue);
    onClose();
  };

  const handleCreateApplications = async (urls: string) => {
    onMultipleClose();
    showToast({
      content: 'Applications creation in progress',
      status: 'loading'
    });
    await createApplicationsMutation({
      variables: {
        urls
      }
    });
  };

  const handleDeleteApplication = async (id: string) => {
    const { data } = await deleteApplicationMutation({
      variables: {
        id
      }
    });
    if (data) {
      if (data.removeApplication) {
        setApplications(applications.filter((a) => a.id !== id));
      }
    }
  };

  const onCoverLetterSelect = async (
    applicationId: string,
    coverLetterId: string,
    companyName?: string
  ) => {
    const application = applications.find((app) => app.id === applicationId);
    if (coverLetterId === 'new' && !application?.coverLetter) {
      const { data } = await createCoverLetterMutation({
        variables: {
          title: companyName ? `${companyName} letter` : '',
          applicationId
        }
      });

      if (data.createCoverLetter) {
        setApplications(
          applications.map((app) =>
            app.id === applicationId
              ? { ...app, coverLetter: data.createCoverLetter }
              : app
          )
        );
      }

      return;
    }
  };

  const onResumeSelect = useCallback(
    async (applicationId: string, resumeId: string) => {
      if (resumeId === 'new') {
        setModalOpen(true);
        return;
      }
      try {
        setIsLoading(true);
        const { data } = await selectApplicationResumeMutation({
          variables: {
            id: applicationId,
            resumeId
          }
        });
        if (data.updateApplication) {
          setApplications(
            applications.map((app) =>
              app.id === data.updateApplication.id
                ? { ...app, ...data.updateApplication }
                : app
            )
          );
        }
      } catch (e) {
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [
      applications,
      selectApplicationResumeMutation,
      setApplications,
      setModalOpen
    ]
  );
  const handleStatusUpdate = useCallback(
    async (id: string, status: EApplicationStatus) => {
      if (id && status) {
        await updateApplicationStatusMutation({
          variables: {
            id,
            status: status.toUpperCase()
          }
        });
      }
    },
    [updateApplicationStatusMutation]
  );

  const initData = useCallback(async () => {
    try {
      await Promise.all([
        initCoverLetters(),
        initResumes(),
        initApplications()
      ]);
    } catch (error) {
      console.log('ERROR', error);
    } finally {
      setLoading(false);
    }
  }, [initCoverLetters, initResumes, initApplications]);

  const onApplicationEdit = useCallback(
    (id: string) => {
      setSearchParams({ id });
      onOpen();
    },
    [onOpen, setSearchParams]
  );

  useEffect(() => {
    initData();
  }, [initData]);

  return {
    onMultipleOpen,
    onOpen,
    loading,
    onApplicationEdit,
    handleDeleteApplication,
    onCoverLetterSelect,
    onResumeSelect,
    handleStatusUpdate,
    isOpen,
    onClose,
    createApplicationLoading,
    updateApplicationLoading,
    handleSubmit,
    onMultipleClose,
    isMultipleOpen,
    application,
    handleCreateApplications
  };
};
