import { gql } from '@apollo/client';

export const CREATE_APPLICATIONS = gql`
  mutation createApplications($urls: String!) {
    createApplications(urls: $urls) {
      id
      company
      coverLetter {
        id
        title
        content
      }
      description
      postDate
      title
      url
      validDate
      resume {
        id
        title
        description
      }
    }
  }
`;
