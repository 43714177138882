import { gql } from '@apollo/client';

export const PROFILES = gql`
  query profiles($type: String) {
    profiles(type: $type) {
      id
      name
      content
    }
  }
`;
