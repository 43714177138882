import { ECVLanguage } from 'src/types/types';

export type TInitialCreateState = {
  language: ECVLanguage;
  title: string;
  industry: string;
  company: string;
  job: string;
  css: string;
};

export enum ECreateModalStep {
  SELECT_TEMPLATE,
  SELECT_VALUES
}

type State = {
  isCreateModalOpen: boolean;
  createModalStep: ECreateModalStep;
  initialCreateState: TInitialCreateState;
  isCVOptionsMenuOpen: boolean;
};

type Action = {
  setCreateModalState: (value: boolean) => void;
  setCreateModalStep: (value: ECreateModalStep) => void;
  setInitialCreateState: (value: Partial<TInitialCreateState>) => void;
  setCVOptionsMenuState: (value: boolean) => void;
};

export type TAppSlice = State & Action;
