import { gql } from '@apollo/client';

export const GET_RECOMMENDATION = gql`
  query findOneRecommendation($id: String!) {
    findOneRecommendation(id: $id) {
      content
      title
      company
      recommenderJobTitle
      recommendeeJobTitle
      recommender {
        name
        email
        currentCompany
        currentJobTitle
      }
      isApproved
    }
  }
`;
