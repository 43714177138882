import * as yup from 'yup';
import i18n from 'i18next';

export const validationLoginSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .max(36)
    .required(i18n.t('login:form.validation.emailRequired')),
  password: yup
    .string()
    .min(6)
    .max(32)
    .required(i18n.t('login:form.validation.passwordRequired'))
});
