import React, { createContext, useContext, useEffect } from 'react';
import {
  ApolloError,
  MutationFunctionOptions,
  MutationResult,
  useLazyQuery,
  useMutation
} from '@apollo/client';
import { REGISTER } from 'src/graphql/mutations/register';
import { LOGIN } from '../../graphql/mutations/login';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers, FormikValues } from 'formik';
import { client, wsClient } from '../../graphql/client';
import { ME } from '../../graphql/queries/me';
import { LINKEDIN_LOGIN } from '../../graphql/mutations/loginWithLinkedIn';
import {
  RESET_PASSWORD,
  RESET_PASSWORD_AUTHORIZED,
  SEND_RESET_PASSWORD_LINK
} from '../../graphql/mutations/resetPassword';
import { CONFIRM_EMAIL } from '../../graphql/mutations/confirmEmail';
import { SUBSCRIPTION } from 'src/graphql/queries';
import { useAuthStore } from 'src/features/auth/model/authStore';

interface IAuthContext {
  register: (
    email: string,
    password: string,
    firstname?: string,
    lastname?: string,
    formikAction?: FormikHelpers<FormikValues>
  ) => void;
  login: (
    email: string,
    password: string,
    formikAction?: FormikHelpers<FormikValues>
  ) => void;
  logout: () => void;
  linkedInLogin: (code: string) => void;
  confirmEmail: (code: string) => void;
  planName: string;
  loginError: ApolloError | undefined;
  confirmEmailError: ApolloError | undefined;
  linkedInLoginError: ApolloError | undefined;
  resetPasswordError: ApolloError | undefined;
  resetPasswordAuthorizedError: ApolloError | undefined;
  registrationResult: MutationResult;
  resetPassword: (
    newPassword: string,
    formikAction?: FormikHelpers<FormikValues>,
    code?: string
  ) => void;
  resetLinkData: any;
  sendResetPasswordLink: (
    options?: MutationFunctionOptions<any, Record<string, any>>
  ) => Promise<any>;
  sendResetLinkError: ApolloError | undefined;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC<any> = React.memo((props) => {
  const navigate = useNavigate();
  const [getMe] = useLazyQuery(ME);
  const { user, setUser, setPlanName, setIsAuth, isAuth } = useAuthStore();
  const [getSubscription] = useLazyQuery(SUBSCRIPTION, {
    onCompleted: (data) => {
      data.subscription.plan && setPlanName(data.subscription.plan);
    }
  });
  const [registerMutation, registrationResult] = useMutation(REGISTER);
  const [loginMutation, loginError] = useMutation(LOGIN);
  const [confirmEmailMutation, confirmEmailError] = useMutation(CONFIRM_EMAIL);
  const [linkedInLoginMutation, linkedInLoginError] =
    useMutation(LINKEDIN_LOGIN);
  const [resetPasswordMutation, resetPasswordError] =
    useMutation(RESET_PASSWORD);
  const [resetPasswordAuthorizedMutation, resetPasswordAuthorizedError] =
    useMutation(RESET_PASSWORD_AUTHORIZED);

  useEffect(() => {
    if (!user && isAuth) {
      getMe()
        .then((res) => setUser(res.data.me))
        .catch((e) => {
          console.log('error', e);
        });
    }

    const unsubscribe = client.onResetStore(async () => {
      setUser(null);
      setIsAuth(false);
    });
    return () => unsubscribe();
  }, [isAuth, user, getMe]);

  useEffect(() => {
    if (user && isAuth) {
      getSubscription();
    }
  }, [user, isAuth, getSubscription]);

  const register = async (
    email: string,
    password: string,
    firstname?: string,
    lastname?: string,
    formikAction?: FormikHelpers<FormikValues>
  ) => {
    await registerMutation({
      variables: { email, password, firstname, lastname }
    });
    if (!!formikAction) {
      formikAction.resetForm();
      formikAction.setSubmitting(false);
    }
  };

  const resetPassword = async (
    newPassword: string,
    formikAction?: FormikHelpers<FormikValues>,
    code?: string
  ) => {
    if (!!formikAction) {
      formikAction.resetForm();
      formikAction.setSubmitting(false);
    }
    if (window.location.pathname.startsWith('/auth')) {
      const data = await resetPasswordMutation({
        variables: { newPassword, code }
      }).then((res) => res.data.resetPassword);
      setUser(data.user);
      setIsAuth(true);
      localStorage.setItem('auth', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      navigate('/');
    } else {
      const data = await resetPasswordAuthorizedMutation({
        variables: { newPassword }
      }).then((res) => res.data.changePassword);
      setUser(data);
      setTimeout(() => navigate('/'), 1500);
    }
  };

  const confirmEmail = async (code: string) => {
    const user = await confirmEmailMutation({ variables: { code } });
    setUser(user.data.confirmEmail.user);
    setIsAuth(true);
    localStorage.setItem('auth', user.data.confirmEmail.accessToken);
    localStorage.setItem('refreshToken', user.data.confirmEmail.refreshToken);
    navigate('/');
  };

  const login = async (
    email: string,
    password: string,
    formikAction?: FormikHelpers<FormikValues>
  ) => {
    const user = await loginMutation({ variables: { email, password } });
    setUser(user.data.login.user);
    setIsAuth(true);
    localStorage.setItem('auth', user.data.login.accessToken);
    localStorage.setItem('refreshToken', user.data.login.refreshToken);
    if (!!formikAction) {
      formikAction.resetForm();
      formikAction.setSubmitting(false);
    }
    navigate('/');
  };

  const linkedInLogin = async (code: string) => {
    const user = await linkedInLoginMutation({ variables: { code } });
    setUser(user.data.linkedInLogin.user);
    setIsAuth(true);
    localStorage.setItem('auth', user.data.linkedInLogin.accessToken);
    localStorage.setItem('refreshToken', user.data.linkedInLogin.refreshToken);
    const changeDataToken = user.data.linkedInLogin.changeDataToken;
    if (changeDataToken) {
      navigate('/confirm-data', { state: { changeDataToken } });
    } else navigate('/');
  };

  const logout = async () => {
    setUser(null);
    setIsAuth(false);
    await client.resetStore();
    wsClient.close(false);
    localStorage.removeItem('auth');
    localStorage.removeItem('refreshToken');
  };
  const [
    sendResetPasswordLink,
    { data: resetLinkData, error: sendResetLinkError }
  ] = useMutation(SEND_RESET_PASSWORD_LINK);
  return (
    <AuthContext.Provider
      value={{
        register,
        login,
        logout,
        resetPassword,
        resetPasswordError: resetPasswordError.error,
        resetPasswordAuthorizedError: resetPasswordAuthorizedError.error,
        loginError: loginError.error,
        registrationResult,
        confirmEmail,
        confirmEmailError: confirmEmailError.error,
        linkedInLogin,
        linkedInLoginError: linkedInLoginError.error,
        sendResetPasswordLink,
        resetLinkData,
        sendResetLinkError
      }}
      {...props}
    />
  );
});

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
