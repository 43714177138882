import { gql } from '@apollo/client';

export const REGISTER = gql`
  mutation signup(
    $email: String!
    $password: String!
    $firstname: String
    $lastname: String
  ) {
    signup(
      data: {
        email: $email
        password: $password
        firstname: $firstname
        lastname: $lastname
      }
    )
  }
`;
